/* eslint-disable react/prop-types */
/* eslint-disable react/display-name */
import React, { createRef, useEffect, useState, useRef } from "react";
import {
	Box,
	Grid,
	makeStyles,
	Tooltip,
	Typography,
	Modal,
	Button,
} from "@material-ui/core";
import { Today } from "@material-ui/icons";
import RefreshIcon from "@material-ui/icons/Refresh";
import MaterialTable, {
	MTableBodyRow,
	MTableCell,
	MTableToolbar,
} from "material-table";
import moment from "moment";
import { useSnackbar } from "notistack";
import PropTypes from "prop-types";
import { DateRange } from "react-date-range";
import * as locales from "react-date-range/dist/locale";
import { useHistory, useLocation } from "react-router-dom";
import { tableIntl } from "config/constants";
import { axiosInstance } from "services/api";
import { useConfirm } from "shared/ConfirmerProvider";
import { Filter } from "shared/Filter";
import { Loader } from "shared/Loader";
import "moment/locale/it";
import { Lookup } from "shared/Lookup";
import { formatCellValue, getErrorMessage } from "utils";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file

moment.locale("it");

const useStyles = makeStyles((theme) => ({
	table: {
		width: "100%",
		marginTop: "12px",
	},
	removeAllFilters: {
		color: theme.palette.primary.main,
		marginLeft: "0.5rem",
		cursor: "pointer",
	},
}));

function useQuery() {
	return new URLSearchParams(useLocation().search);
}

const headerStyle = {
	padding: "2px 16px",
	position: "sticky",
	fontSize: "0.8125rem",
	top: 0,
};
const filterCellStyle = {
	backgroundColor: "white",
	padding: "4px 16px",
	position: "sticky",
	top: 25,
	fontSize: "0.8125rem",
	maxWidth: "140px",
	minWidth: "140px",
	overflow: "hidden",
};
const cellStyle = {
	fontSize: "0.8125rem", // 100:16=x:13 => 1300/16
	width: "140px",
	minWidth: "140px",
	maxWidth: "140px",
	overflow: "hidden",
	whiteSpace: "nowrap",
	textOverflow: "ellipsis",
	padding: "2px 16px",
};

const actionsCellStyle = {
	width: "1%",
	whiteSpace: "nowrap",
	textAlign: "right",
};

const lookups = {
	rid: {
		sub_label: {
			ADESIONE: "Adesione",
			REVOCA: "Revoca",
			STATO_12: "Stato 12",
			VARIAZIONE: "Variazione",
		},
		compagnia: { ITA: "ITA", RMA: "RMA" },
		status: {
			d_ag_m_d: "Agente con dati mancanti",
			azure_wait_ack: "Attesa da RPA",
			cliente_no_link: "Cliente senza invio link",
			closed: "Caso Chiuso - OK RPA",
			inactive: "Caso da non lavorare",
			sentvalidation: "In attesa di validazione da parte dell'utente",
			isaac: "ISAAC",
			d_no_valid_np: "Numero polizza non valido",
			non_handable_piteco_12: "Polizza che non necessita lavorazione",
			pol_out_of_range: "Polizze non di competenza Uff. Portafoglio",
			azure_to_confirm: "Pronta per RPA",
			deleted: "Richiesta chiusa da operatore",
			d_generic: "Richiesta con errore generico",
			d_rpa_ko: "Richiesta con KO su RPA",
			d_no_label: "Richiesta non classificabile",
			d_no_atts: "Richiesta RID agente senza allegati",
			d_expired: "Richiesta scaduta",
			status_12_completed: "Richieste stato 12 completate",
			rpa_retry: "Riproposizione per RPA",
			non_handable_rpa_ko: "RPA KO non reiterabile",
			ko_rpa_manual_retry: "RPA KO validabile manualmente",
			status_12_wait_notify: "Stato 12 in attesa di invio notifica",
		},
	},
	dichiarazione: {
		sub_label: {
			ISEE: "Lettere ISEE",
			LETTERE_BILANCIO: "Lettere di bilancio",
		},
		compagnia: { ITA: "ITA", RMA: "RMA" },
		status: {
			azure_wait_ack: "Attesa da RPA",
			sentvalidation_d: "In attesa di validazione da parte dell'utente",
			iter_erroneo: "Iter erroneo",
			deleted: "Richiesta chiusa da operatore",
			completed: "Richiesta completata",
			d_generic: "Richiesta con errore generico",
			d_rpa_ko: "Richiesta con KO su RPA",
			d_no_label: "Richiesta non classificabile",
			rpa_retry: "Riproposizione per RPA",
			non_handable_rpa_ko: "RPA KO non reiterabile",
			ko_rpa_manual_retry: "RPA KO validabile manualmente",
			verifica_delega: "Verifica delega",
			blocco_vis: "Richieste in blocco VIS",
			isee_non_dovuto: "ISEE non dovuto",
		},
	},
};

export function Table({ statuses, isReadOnly, type }) {
	const classes = useStyles();
	const query = useQuery();
	const confirm = useConfirm();
	const history = useHistory();
	const tableRef = createRef();
	const topScrollRef = useRef();
	const { enqueueSnackbar } = useSnackbar();

	const queryParams = {
		default: {
			compagnia:
				query.get("compagnia") && query.get("compagnia").split(","),
			tipoRichiesta:
				query.get("sub_label") && query.get("sub_label").split(","),
			data: query.get("date_intervals"),
			stato: query.get("status") && query.get("status").split(","),
			numeroPolizza: query.get("numero_polizza"),
			provenienza: query.get("provenienza"),
			mittente: query.get("email_sender"),
			skip: query.get("skip"),
			limit: query.get("limit"),
			orderBy: query.get("order_by"),
			orderDirection: query.get("order"),
		},
		rid: {
			contraente: query.get("contraente"),
			origine: query.get("sender"),
		},
		dichiarazione: {
			date: query.get("date"),
		},
	};

	const [filters, setFilters] = useState({
		...queryParams.default,
		...queryParams[type],
	});
	const [loading, setLoading] = useState(true);
	const [total, setTotal] = useState(0);
	const [fullTotal, setFullTotal] = useState(0);
	const [forceRefresh, setForceRefresh] = useState(false);
	const [firstStart, setFirstStart] = useState(true);
	const [tableBodyWidth, setTableBodyWidth] = useState(0);
	const [showCalendar, setShowCalendar] = useState(false);
	const [selectedRow, setSelectedRow] = useState();

	const columnsList = {
		rid: [
			{
				title: "Data",
				field: "email_date",
				cellStyle: {
					fontSize: "0.8125rem", // 100:16=x:13 => 1300/16
					width: "3%",
					minWidth: "155px",
					maxWidth: "155px",
					overflow: "hidden",
					whiteSpace: "nowrap",
					textOverflow: "ellipsis",
					padding: "2px 16px",
				},
				defaultFilter: filters.data,
				type: "date",
				filterComponent: (props) => (
					<Button
						style={{ fontSize: "0.6rem" }}
						startIcon={<Today />}
						onClick={() => setShowCalendar(props)}
					>
						Seleziona data
					</Button>
				),
			},
			{
				title: "Tipo richiesta",
				field: "sub_label",
				cellStyle: {
					fontSize: "0.8125rem", // 100:16=x:13 => 1300/16
					width: "10%",
					minWidth: "120px",
					maxWidth: "120px",
					overflow: "hidden",
					whiteSpace: "nowrap",
					textOverflow: "ellipsis",
					padding: "2px 16px",
				},
				defaultFilter: filters.tipoRichiesta,
				lookup: lookups[type].sub_label,
			},
			{
				title: "Compagnia",
				field: "compagnia",
				cellStyle: {
					fontSize: "0.8125rem", // 100:16=x:13 => 1300/16
					width: "3%",
					minWidth: "50px",
					maxWidth: "50px",
					overflow: "hidden",
					whiteSpace: "nowrap",
					textOverflow: "ellipsis",
					padding: "2px 16px",
				},
				defaultFilter: filters.compagnia,
				lookup: lookups[type].compagnia,
			},
			{
				title: "Num. Polizza",
				field: "numero_polizza",
				defaultFilter: filters.numeroPolizza,
				cellStyle,
			},
			{
				title: "Contraente",
				field: "contraente",
				defaultFilter: filters.contraente,
				cellStyle,
			},
			{
				title: "Mittente",
				field: "email_sender",
				defaultFilter: filters.mittente,
				cellStyle,
				list: true,
			},
			{
				title: "Origine",
				field: "sender",
				defaultFilter: filters.origine,
				cellStyle,
			},
			{
				title: "Provenienza",
				field: "provenienza",
				defaultFilter: filters.provenienza,
				cellStyle: {
					fontSize: "0.8125rem", // 100:16=x:13 => 1300/16
					width: "3%",
					minWidth: "100px",
					maxWidth: "100px",
					overflow: "hidden",
					whiteSpace: "nowrap",
					textOverflow: "ellipsis",
					padding: "2px 16px",
				},
			},
			{
				title: "Stato",
				field: "status",
				defaultFilter: filters.stato,
				cellStyle,
				filterComponent: ({ onFilterChanged, columnDef }) => (
					<Lookup
						onFilterChanged={onFilterChanged}
						columnDef={columnDef}
					/>
				),
				lookup: lookups[type].status,
			},
		],
		dichiarazione: [
			{
				title: "Data",
				field: "email_date",
				cellStyle: {
					fontSize: "0.8125rem", // 100:16=x:13 => 1300/16
					width: "3%",
					minWidth: "155px",
					maxWidth: "155px",
					overflow: "hidden",
					whiteSpace: "nowrap",
					textOverflow: "ellipsis",
					padding: "2px 16px",
				},
				defaultFilter: filters.data,
				type: "date",
				filterComponent: (props) => (
					<Button
						style={{ fontSize: "0.6rem" }}
						startIcon={<Today />}
						onClick={() => setShowCalendar(props)}
					>
						Seleziona data
					</Button>
				),
			},
			{
				title: "Tipo richiesta",
				field: "sub_label",
				cellStyle: {
					fontSize: "0.8125rem", // 100:16=x:13 => 1300/16
					width: "10%",
					minWidth: "120px",
					maxWidth: "120px",
					overflow: "hidden",
					whiteSpace: "nowrap",
					textOverflow: "ellipsis",
					padding: "2px 16px",
				},
				defaultFilter: filters.tipoRichiesta,
				lookup: lookups[type].sub_label,
			},
			{
				title: "Compagnia",
				field: "compagnia",
				cellStyle: {
					fontSize: "0.8125rem", // 100:16=x:13 => 1300/16
					width: "3%",
					minWidth: "100px",
					maxWidth: "100px",
					overflow: "hidden",
					whiteSpace: "nowrap",
					textOverflow: "ellipsis",
					padding: "2px 16px",
				},
				defaultFilter: filters.compagnia,
				lookup: lookups[type].compagnia,
			},
			{
				title: "Num. Polizza",
				field: "numero_polizza",
				defaultFilter: filters.numeroPolizza,
				cellStyle,
			},
			{
				title: "Mittente",
				field: "email_sender",
				defaultFilter: filters.mittente,
				cellStyle,
				list: true,
			},
			{
				title: "Date",
				field: "date",
				defaultFilter: filters.date,
				cellStyle,
			},
			{
				title: "Provenienza",
				field: "provenienza",
				defaultFilter: filters.provenienza,
				cellStyle: {
					fontSize: "0.8125rem", // 100:16=x:13 => 1300/16
					width: "3%",
					minWidth: "100px",
					maxWidth: "100px",
					overflow: "hidden",
					whiteSpace: "nowrap",
					textOverflow: "ellipsis",
					padding: "2px 16px",
				},
			},
			{
				title: "Stato",
				field: "status",
				defaultFilter: filters.stato,
				cellStyle,
				filterComponent: ({ onFilterChanged, columnDef }) => (
					<Lookup
						onFilterChanged={onFilterChanged}
						columnDef={columnDef}
					/>
				),
				lookup: lookups[type].status,
			},
		],
	};

	const emptyDateRange = {
		selection1: {
			startDate: null,
			endDate: new Date(""),
			key: "selection1",
		},
		selection2: {
			startDate: null,
			endDate: new Date(""),
			key: "selection2",
		},
		selection3: {
			startDate: null,
			endDate: new Date(""),
			key: "selection3",
		},
	};
	// WORKAROUND PER NON PERDERE I VALUES DEI FILTRI AL REFRESH (MATERIAL TABLE BUG)
	const [columns, setColumns] = useState(columnsList[type]);

	function handleTableRefresh() {
		tableRef.current.onQueryChange();
	}

	async function handleDocumentDelete(id) {
		if (
			await confirm({
				title: "Chiusura richiesta",
				body: "Chiudere la richiesta selezionata?",
				confirmLabel: "Chiudi",
				cancelLabel: "Annulla",
			})
		) {
			axiosInstance
				.delete(`/${type}/services/dashboard/docs/${id}`)
				.then(() => {
					enqueueSnackbar("Richiesta chiusa correttamente", {
						variant: "success",
					});
					setForceRefresh(true);
				})
				.catch((error) => {
					enqueueSnackbar(getErrorMessage(error), {
						variant: "error",
					});
				});
		}
	}

	async function onValidate(row) {
		if (
			await confirm({
				title: "Approvazione richiesta",
				body: "Approvare la richiesta selezionata?",
				confirmLabel: "Valida",
				cancelLabel: "Annulla",
			})
		) {
			const toSend = {
				label: row.label,
				sub_label: row.sub_label,
				sender: row.sender,
				agenzia: row.agenzia,
				client_type: row.client_type,
			};
			axiosInstance
				.put(`/${type}/services/dashboard/docs/${row._id}`, toSend)
				.then(() => {
					enqueueSnackbar("Richiesta approvata correttamente", {
						variant: "success",
					});
					setForceRefresh(true);
				})
				.catch((error) => {
					enqueueSnackbar(getErrorMessage(error), {
						variant: "error",
					});
				});
		}
	}

	useEffect(() => {
		if (forceRefresh) {
			handleTableRefresh();
			setForceRefresh(false);
		}
	}, [forceRefresh]);

	useEffect(() => {
		if (type && !firstStart) {
			resetFilters();
			setColumns(columnsList[type]);
		}

		window.addEventListener("beforeunload", () => {
			localStorage.removeItem("id");
		});
	}, [type]);

	function resetFilters() {
		const self = tableRef.current; // get the tableRef
		const query = self.state.query; // get the query obj
		self.setState({
			...self.dataManager.getRenderState(), // set the render state
			query: {
				...query,
				filters: [],
			},
		});
		// you also need to update the filter value on the column by doing this:
		for (let item in tableRef.current.state.query.filters) {
			let tableColumn = tableRef.current.state.query.filters[item].column;
			tableRef.current.dataManager.changeFilterValue(
				tableColumn.tableData.id,
				null
			);
		}
		setDates(emptyDateRange);
		setForceRefresh(true);
	}

	function refreshData(query) {
		const limit = firstStart
			? !isNaN(Number(queryParams.default.limit)) &&
			  Number(queryParams.default.limit) !== 0
				? Number(queryParams.default.limit)
				: 20
			: query.pageSize;

		const skip = firstStart
			? !isNaN(Number(queryParams.default.skip))
				? Number(queryParams.default.skip)
				: 0
			: limit * query.page;

		return new Promise((resolve, reject) => {
			function generateQueryParams(query) {
				const toSend = {
					skip,
					limit,
					order: query.orderDirection
						? query.orderDirection === "asc"
							? 1
							: -1
						: queryParams.default.orderDirection || -1,
					order_by:
						query.orderBy?.field ||
						queryParams.default.orderBy ||
						"email_date",
				};
				const newFilters = {};

				query.filters.forEach((filter) => {
					if (filter?.column?.field === "email_date") {
						toSend["date_intervals"] = filter.value;
						newFilters["Data"] = "";
						filter.value.split(",").forEach((rangeDate) => {
							const arrayDates = rangeDate.split("|");
							if (newFilters["Data"].length > 0)
								newFilters["Data"] =
									`${newFilters["Data"]} | ` +
									`${moment(arrayDates[0]).format(
										"DD/MM/yyyy"
									)} 
										- ${moment(arrayDates[1]).format("DD/MM/yyyy")}`;
							else
								newFilters["Data"] = `Data: ${moment(
									arrayDates[0]
								).format("DD/MM/yyyy")}
										- ${moment(arrayDates[1]).format("DD/MM/yyyy")}`;
						});
					} else {
						if (filter.value.toString()) {
							if (Array.isArray(filter.value)) {
								if (filter.value.length > 3) {
									const newArray = filter.value.slice(0, 3);
									const remains = filter.value.length - 3;
									const formattedLabels = newArray.map(
										(value) =>
											lookups[type][filter.column.field][
												value
											]
									);

									newFilters[filter.column.title] = `${
										filter.column.title
									}: ${formattedLabels.toString()} + ${remains}`;
								} else {
									const formattedLabels = filter.value.map(
										(value) => {
											return lookups[type][
												filter.column.field
											][value];
										}
									);

									newFilters[filter.column.title] = `${
										filter.column.title
									}: ${formattedLabels.toString()}`;
								}
							} else {
								newFilters[filter.column.title] = `${
									filter.column.title
								}: ${filter.value.toString()}`;
							}
							if (filter.value.toString() === "azure_wait_ack") {
								toSend[
									filter.column.field
								] = `${filter.value.toString()},azure_wait_ack_in_folder`;
							} else {
								toSend[filter.column.field] =
									filter.value.toString();
							}
						}
					}
				});

				setFilters(newFilters);

				history.push({
					pathname: `/dashboard/${type}`,
					search: new URLSearchParams(toSend).toString(),
				});

				return toSend;
			}

			setLoading(true);
			axiosInstance
				.get(`/${type}/services/dashboard/docs`, {
					params: { ...generateQueryParams(query), label: type },
				})
				.then(({ data }) => {
					setTotal(data.total);
					setFullTotal(data.full);

					const page = skip / limit;

					//Smell
					data.documents.forEach((doc) => {
						if (doc.status === "azure_wait_ack_in_folder")
							doc.status = "azure_wait_ack";
					});

					resolve({
						data: data.documents,
						page: firstStart ? page : query.page,
						totalCount: data.total,
					});

					if (firstStart) {
						setFirstStart(false);
					}

					setLoading(false);

					// SCROLL INTO PREVIOUS SELECTED DOCUMENT
					setTimeout(() => {
						const row = document.getElementById(
							localStorage.getItem("id")
						);
						row &&
							row.scrollIntoView({
								block: "center",
								inline: "nearest",
							});
					}, 50);
				})
				.catch((err) => {
					reject(err);
					setLoading(false);
				});
		});
	}

	useEffect(() => {
		const tableDivElement = document.querySelector("[class^=MuiTable-root]")
			?.parentElement.parentElement;
		if (tableDivElement) {
			topScrollRef.current.onscroll = () => {
				setTableBodyWidth(tableDivElement.scrollWidth);
				tableDivElement.scrollLeft = topScrollRef.current.scrollLeft;
			};
			tableDivElement.onscroll = () =>
				(topScrollRef.current.scrollLeft = tableDivElement.scrollLeft);
			setTableBodyWidth(tableDivElement.scrollWidth);
		}
	});

	const [dates, setDates] = useState(emptyDateRange);

	const tableTitleMapping = {
		rid: "Mandati SDD",
		dichiarazione: "Dichiarazione ISEE/Lettere Bilancio"
	}

	// function scroll(direction) {
	// 	const pixelToScroll = 10;
	// 	const tableDivElement = document.querySelector(
	// 		"[class^=Component-horizontalScrollContainer]"
	// 	).children[0].children[0];
	// 	if (direction === "right") tableDivElement.scrollLeft += pixelToScroll;
	// 	else tableDivElement.scrollLeft -= pixelToScroll;
	// }
	return (
		<Box className={classes.table}>
			<Loader isLoading={loading || !statuses}>
				<Modal
					style={{ top: "20%", left: "3%" }}
					open={!!showCalendar}
					onClose={() => setShowCalendar(null)}
					aria-labelledby="modal-modal-title"
					aria-describedby="modal-modal-description"
				>
					<Box
						sx={{
							width: "min-content",
							backgroundColor: "#fafafa",
						}}
					>
						<DateRange
							locale={locales.it}
							onChange={(item) => setDates({ ...dates, ...item })}
							showMonthAndYearPickers={false}
							showDateDisplay={false}
							maxDate={new Date()}
							inputRanges={[]}
							ranges={[
								dates.selection1,
								dates.selection2,
								dates.selection3,
							]}
						/>
						<Box
							sx={{
								textAlign: "right",
								padding: "0.5rem 0.5rem",
							}}
						>
							<Button
								component="span"
								variant="text"
								color="primary"
								onClick={() => {
									setDates(emptyDateRange);
									showCalendar.onFilterChanged(
										showCalendar.columnDef.tableData.id,
										null
									);
									setShowCalendar(null);
								}}
							>
								Rimuovi
							</Button>
							<Button
								component="span"
								variant="contained"
								color="primary"
								style={{ marginLeft: "0.8rem" }}
								onClick={() => {
									let rangeDateString = "";
									Object.keys(dates).forEach((selection) => {
										if (dates[selection].startDate) {
											if (rangeDateString.length > 0)
												rangeDateString = `${rangeDateString},${moment(
													dates[selection].startDate
												).format(
													"YYYY-MM-DD"
												)}|${moment(
													dates[selection].endDate
												).format("YYYY-MM-DD")}`;
											else
												rangeDateString = `${moment(
													dates[selection].startDate
												).format(
													"YYYY-MM-DD"
												)}|${moment(
													dates[selection].endDate
												).format("YYYY-MM-DD")}`;
										}
									});
									if (rangeDateString.length > 0)
										showCalendar.onFilterChanged(
											showCalendar.columnDef.tableData.id,
											rangeDateString
										);
									setShowCalendar(null);
								}}
							>
								Ok
							</Button>
						</Box>
					</Box>
				</Modal>
				<MaterialTable
					title={`Lista richieste ${tableTitleMapping[type]} (${total} di ${fullTotal})`}
					tableRef={tableRef}
					columns={columns}
					localization={tableIntl}
					actions={
						!isReadOnly
							? [
									(rowData) => ({
										icon: "check",
										iconProps: {
											padding: "0",
											fontSize: "small",
										},
										tooltip: "Approva richiesta",
										onClick: (event, rowData) =>
											onValidate(rowData),
										hidden:
											rowData.status !==
											"azure_to_confirm",
									}),
									{
										icon: "clear",
										iconProps: {
											padding: "0",
											fontSize: "small",
										},
										tooltip: "Chiude richesta",
										onClick: (event, rowData) =>
											handleDocumentDelete(rowData._id),
									},
									{
										icon: () => <RefreshIcon />,
										tooltip: "Aggiorna lista",
										isFreeAction: true,
										onClick: () => handleTableRefresh(),
									},
							  ]
							: []
					}
					onRowClick={(evt, selectedRow) => {
						switch (evt.detail) {
							case 1:
								setSelectedRow(selectedRow._id);
								break;
							case 2:
								history.push(
									`/dashboard/${type}/${selectedRow._id}`
								);
								break;
							default:
								history.push(
									`/dashboard/${type}/${selectedRow._id}`
								);
						}
					}}
					options={{
						draggable: false,
						search: false,
						filtering: true,
						actionsColumnIndex: -1,
						pageSize:
							!isNaN(Number(queryParams.default.limit)) &&
							Number(queryParams.default.limit) !== 0
								? Number(queryParams.default.limit)
								: 20,
						pageSizeOptions: [10, 20, 50, 100],
						loadingType: null,
						headerStyle,
						filterCellStyle,
						actionsCellStyle,
						debounceInterval: 400,
						maxBodyHeight: 400,
					}}
					components={{
						Row: (props) => (
							<MTableBodyRow {...props} id={props.data._id} />
						),
						Cell: (props) => (
							<Tooltip
								title={
									props.value
										? props.columnDef.list &&
										  Array.isArray(props.value)
											? props.value.join(", ")
											: props.columnDef.type === "date" ||
											  props.columnDef.type ===
													"datetime"
											? moment(props.value).format(
													"DD/MM/YYYY, HH:mm:ss"
											  )
											: props.value.toString()
										: ""
								}
								placement="top-start"
								arrow
							>
								<MTableCell
									style={{
										backgroundColor:
											localStorage.getItem("id") ===
											props.rowData._id
												? "rgba(49, 126, 217, 0.3)"
												: selectedRow ===
												  props.rowData._id
												? "#ffff4d"
												: "transparent",
									}}
									{...props}
									value={formatCellValue(props.value)}
								/>
							</Tooltip>
						),
						Toolbar: (props) => (
							<div>
								<MTableToolbar {...props} />
								{!loading && (
									<div
										style={{
											margin: "-1rem 1rem 2rem",
											padding: "0px 10px",
										}}
									>
										<Grid
											container
											spacing={(4, 2)}
											style={{
												marginTop: "1rem",
												display: "flex",
												alignItems: "center",
											}}
										>
											{Object.keys(filters)
												.filter((key) => filters[key])
												.map((key) => (
													<Filter
														key={key}
														label={filters[key]}
													/>
												))}
											{Object.keys(filters).length >
												0 && (
												<Typography
													variant="body2"
													onClick={resetFilters}
													className={
														classes.removeAllFilters
													}
												>
													Rimuovi tutti i filtri
												</Typography>
											)}
										</Grid>
									</div>
								)}
								<Box
									ref={topScrollRef}
									className="topTableScroll"
									style={{
										height: "25px",
										overflow: "auto hidden",
									}}
								>
									<Box
										style={{
											height: "25px",
											width: tableBodyWidth,
										}}
									/>
								</Box>
							</div>
						),
					}}
					data={refreshData}
				/>
			</Loader>
		</Box>
	);
}
Table.propTypes = {
	statuses: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.arrayOf(PropTypes.string),
	]),
};
