import React, { Fragment, useEffect, useState } from "react";
import {
	Drawer,
	Divider,
	IconButton,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { GetApp } from "@material-ui/icons";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import PersonIcon from "@material-ui/icons/Person";
import Settings from "@material-ui/icons/Settings";
import FileSaver from "file-saver";
import PropTypes from "prop-types";
import { Link, useLocation } from "react-router-dom";
import logo from "assets/images/logo.png";
import { drawerWidth } from "config/constants";
import { axiosInstance } from "services/api";

const useStyles = makeStyles((theme) => ({
	root: {
		display: "flex",
	},
	appBar: {
		transition: theme.transitions.create(["margin", "width"], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
	},
	appBarShift: {
		width: `calc(100% - ${drawerWidth}px)`,
		marginLeft: drawerWidth,
		transition: theme.transitions.create(["margin", "width"], {
			easing: theme.transitions.easing.easeOut,
			duration: theme.transitions.duration.enteringScreen,
		}),
	},
	hide: {
		display: "none",
	},
	drawer: {
		width: drawerWidth,
		flexShrink: 0,
	},
	drawerPaper: {
		width: drawerWidth,
	},
	drawerHeader: {
		display: "flex",
		alignItems: "center",
		padding: theme.spacing(0, 1, 0, 3),
		...theme.mixins.toolbar,
		justifyContent: "space-between",
	},
	drawerFooter: {
		marginTop: "auto",
	},
	nested: {
		paddingLeft: theme.spacing(4),
	},
	subNested: {
		paddingLeft: theme.spacing(7),
	},
}));

export function Sidebar({ open, handleDrawerClose, onLogout, roles, type }) {
	const classes = useStyles();
	const location = useLocation();
	const [route, setRoute] = useState(null);

	useEffect(() => {
		if (location) {
			const path = location.pathname.split("/");
			setRoute(path[path.length - 1]);
		}
	}, [location]);

	const [selectedItem, setSelectedItem] = useState(null);

	const onFileDownload = (manuale) => {
		axiosInstance
			.get(`/rid/services/dashboard/${manuale}`, {
				responseType: "arraybuffer",
			})
			.then(({ data }) => {
				const fileData = new Blob([data]);
				FileSaver.saveAs(
					fileData,
					manuale === "guide"
						? "Manuale_piattaforma.pdf"
						: "Manuale_moduli_editabili.pdf"
				);
			});
	};

	const sidebarElements = {
		main: [
			{
				label: "Mandati SDD",
				path: "/dashboard/rid",
				authorized: () =>
					roles.isConfig || roles.isUffVita || roles.isITUser,
				children: [
					{
						label: "Le mie attività",
						path: "/dashboard/rid?status=sentvalidation,isaac,d_no_valid_np,d_ag_m_d,cliente_no_link,d_generic,d_no_atts",
					},
				],
			},
			{
				label: "Dichiarazione ISEE/Lettere Bilancio",
				path: "/dashboard/dichiarazione",
				authorized: () =>
					roles.isConfig || roles.isUffVita || roles.isITUser,
				children: [
					{
						label: "Le mie attività",
						path: "/dashboard/dichiarazione?status=no_agenzia,codice_agenzia_errato,sentvalidation_d,intermediario_no_att,n_pol_empty,verifica_delega",
					},
				],
			},
		],
		footer: [
			{
				label: "Download manuale",
				action: () => onFileDownload("guide"),
				icon: <GetApp />,
			},
			{
				label: "Download manuale moduli editabili",
				action: () => onFileDownload("module"),
				icon: <GetApp />,
			},
			{
				label: "Logout",
				action: () => onLogout(),
				icon: <PersonIcon />,
			},
			{
				label: "Settings",
				path: "/dashboard/settings",
				icon: <Settings />,
			},
		],
	};

	return (
		<>
			<Drawer
				variant="persistent"
				className={classes.drawer}
				onClose={handleDrawerClose}
				anchor="left"
				open={open}
				classes={{
					paper: classes.drawerPaper,
				}}
			>
				<div className={classes.drawerHeader}>
					<div className={classes.logo}>
						<img src={logo} alt="Reale Mutua" width="140px" />
					</div>
					<IconButton onClick={handleDrawerClose}>
						<ChevronLeftIcon />
					</IconButton>
				</div>
				<Divider />
				<List>
					{sidebarElements.main.map((element) =>
						element.path ? (
							<Fragment key={element.label}>
								<ListItem
									button
									selected={
										selectedItem === element.label ||
										route === element.label.toLowerCase()
									}
									onClick={() => {
										setSelectedItem(element.label);
									}}
									component={Link}
									to={element.path}
									disabled={!element.authorized}
								>
									<ListItemText primary={element.label} />
								</ListItem>
								{element.children && (
									<List>
										{element.children.map((c, i) => (
											<ListItem
												className={classes.nested}
												key={i}
												component={Link}
												onClick={() =>
													(window.location.href =
														c.path)
												}
											>
												{c.label}
											</ListItem>
										))}
									</List>
								)}
							</Fragment>
						) : (
							<ListItem
								key={element.label}
								button
								onClick={element.action}
							>
								<ListItemIcon>{element.icon}</ListItemIcon>
								<ListItemText primary={element.label} />
							</ListItem>
						)
					)}
				</List>
				<Divider className={classes.drawerFooter} />
				<List>
					{sidebarElements.footer.map((element) => (
						<Fragment key={element.label}>
							{element.path ? (
								<ListItem
									button
									selected={selectedItem === element.label}
									onClick={() =>
										setSelectedItem(element.label)
									}
									component={Link}
									to={element.path}
								>
									<ListItemIcon>{element.icon}</ListItemIcon>
									<ListItemText primary={element.label} />
								</ListItem>
							) : (
								<ListItem
									key={element.label}
									button
									onClick={element.action}
								>
									<ListItemIcon>{element.icon}</ListItemIcon>
									<ListItemText primary={element.label} />
								</ListItem>
							)}
						</Fragment>
					))}
				</List>
			</Drawer>
		</>
	);
}

Sidebar.propTypes = {
	open: PropTypes.bool,
	handleDrawerClose: PropTypes.func,
	onLogout: PropTypes.func.isRequired,
};
