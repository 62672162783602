/* eslint-disable react/display-name */
import React, { useEffect, useState } from "react";
import {
	Box,
	FormControlLabel,
	makeStyles,
	Switch,
	Typography,
} from "@material-ui/core";
import MaterialTable from "material-table";
import { useSnackbar } from "notistack";
import { tableIntl } from "config/constants";
import { axiosInstance } from "services/api";
import { getErrorMessage } from "utils";

const useStyles = makeStyles((theme) => ({
	table: {
		width: "100%",
		marginTop: "12px",
	},
	actions: {
		display: "flex",
		justifyContent: "flex-end",
		margin: "2rem 0 1rem",
	},
	action: {
		marginLeft: "1rem",
		boxShadow: "0 0 0 #000",
		backgroundColor: theme.palette.secondary.main,
	},
	receiverText: {
		fontSize: "0.8125rem",
		fontFamily: "inherit",
		fontWeight: "inherit",
	},
}));

export function ReceiversSettings({ roles }) {
	const classes = useStyles();
	const [columns, setColumns] = useState([]);
	const [receivers, setReceivers] = useState([]);
	const [data, setData] = useState([]);
	const [dataToUpdate, setDataToUpdate] = useState(null);

	const [chargeValue, setChargeValue] = useState([]);
	const [dispValue, setDispValue] = useState([]);

	const [activeRow, setActiveRow] = useState(null);

	const { enqueueSnackbar } = useSnackbar();

	const cellStyle = {
		fontSize: "0.8125rem", // 100:16=x:13 => 1300/16
		width: "140px",
		minWidth: "140px",
		maxWidth: "140px",
		overflow: "hidden",
		whiteSpace: "nowrap",
		textOverflow: "ellipsis",
		padding: "2px 16px",
	};

	function getReceivers() {
		axiosInstance
			.get("/rid/services/receiverconf/show")
			.then(({ data }) => {
				setReceivers(data);
			});
	}

	function updateReceivers() {
		axiosInstance
			.put("/rid/services/receiverconf/update", dataToUpdate)
			.then(() => {
				enqueueSnackbar(`Impostazioni aggiornate correttamente`, {
					variant: "success",
				});
				getReceivers();
			})
			.catch((error) => {
				enqueueSnackbar(getErrorMessage(error), {
					variant: "error",
				});
			});
	}

	const handleSetDataToUpdate = () => {
		const newData = data.map((receiver) => {
			let id,
				agenzia = "";
			const ids = receivers.filter(
				(rec) =>
					rec.sender ===
						receiver.receiver.split(" ")[0].toLowerCase() &&
					rec.agenzia === receiver.receiver.split(" ")[1]
			);
			if (ids.length > 0) {
				agenzia = receiver.receiver.split(" ")[1];
				id = ids[0]._id;
			} else {
				agenzia = null;
				const ids_null = receivers.filter(
					(rec) => rec.agenzia === null
				);
				if (ids_null.length > 0) {
					id = ids_null[0]._id;
				}
			}

			return {
				sender: receiver.receiver.split(" ")[0].toLowerCase(),
				agenzia,
				take_in_charge: receiver.takeInCharge === "invio email",
				dispatched: receiver.dispatched === "invio email",
				_id: id,
			};
		});

		setDataToUpdate(newData);
	};

	const handleChargeChange = (checked, rowData) => {
		setActiveRow(rowData);
		const val = checked ? "invio email" : "nessuna email";
		rowData.takeInCharge = val;
		setActiveRow(rowData);
	};

	const handleDispatchedChange = (checked, rowData) => {
		setActiveRow(rowData);
		const val = checked ? "invio email" : "nessuna email";
		rowData.dispatched = val;
		setActiveRow(rowData);
	};

	function handleSetColumns() {
		setColumns([
			{
				title: "Tipologia",
				field: "receiver",
				editable: "never",
				cellStyle,
			},
			{
				title: "Presa in carico",
				field: "takeInCharge",
				cellStyle,
				render: (rowData) =>
					roles.isConfig ? (
						<FormControlLabel
							control={
								<Switch
									checked={
										rowData.takeInCharge === "invio email"
									}
									onChange={(e) => {
										handleChargeChange(
											e.target.checked,
											rowData
										);
									}}
									name="checkedB"
									color="primary"
								/>
							}
							label={
								<Typography className={classes.receiverText}>
									{rowData.takeInCharge}
								</Typography>
							}
						/>
					) : (
						<Typography className={classes.receiverText}>
							{rowData.dispatched}
						</Typography>
					),
			},
			{
				title: "Richiesta completata",
				field: "dispatched",
				cellStyle,
				render: (rowData) =>
					roles.isConfig ? (
						<FormControlLabel
							className={classes.receiverText}
							control={
								<Switch
									checked={
										rowData.dispatched === "invio email"
									}
									onChange={(e) => {
										handleDispatchedChange(
											e.target.checked,
											rowData
										);
									}}
									name="checkedB"
									color="primary"
									size="sm"
								/>
							}
							label={
								<Typography className={classes.receiverText}>
									{rowData.dispatched}
								</Typography>
							}
						/>
					) : (
						<Typography className={classes.receiverText}>
							{rowData.dispatched}
						</Typography>
					),
			},
		]);
	}
	useEffect(() => {
		getReceivers();
	}, []);

	useEffect(() => {
		handleSetColumns();
		if (receivers && receivers.length > 0) {
			const receiversData = receivers.map((receiver) => {
				const charge = receiver.take_in_charge
					? "invio email"
					: "nessuna email";
				const disp = receiver.dispatched
					? "invio email"
					: "nessuna email";
				const agenzia = receiver.agenzia
					? receiver.agenzia
					: "senza compagnia";
				setChargeValue([...chargeValue, charge]);
				setDispValue([...dispValue, disp]);
				return {
					receiver: `${
						receiver.sender.charAt(0).toUpperCase() +
						receiver.sender.slice(1)
					} ${agenzia}`,
					takeInCharge: charge,

					dispatched: disp,
				};
			});
			setData(receiversData);
		}
	}, [receivers]);

	useEffect(() => {
		handleSetColumns();
		if (activeRow) {
			const receiversData = receivers.map((receiver) => {
				let charge = receiver.take_in_charge
					? "invio email"
					: "nessuna email";
				let disp = receiver.dispatched
					? "invio email"
					: "nessuna email";
				const agenzia = receiver.agenzia
					? receiver.agenzia
					: "senza compagnia";
				const rec = `${
					receiver.sender.charAt(0).toUpperCase() +
					receiver.sender.slice(1)
				} ${agenzia}`;
				if (activeRow.receiver === rec) {
					charge = activeRow.takeInCharge;
					disp = activeRow.dispatched;
					setActiveRow(null);
				}
				setChargeValue([...chargeValue, charge]);
				setDispValue([...dispValue, disp]);
				return {
					receiver: rec,
					takeInCharge: charge,
					dispatched: disp,
				};
			});

			setData(receiversData);
			handleSetDataToUpdate();
		}
	}, [activeRow]);

	useEffect(() => {
		if (dataToUpdate) {
			updateReceivers();
		}
	}, [dataToUpdate]);

	return (
		<Box className={classes.table}>
			{data && (
				<MaterialTable
					title="Invio email"
					columns={columns}
					data={data}
					options={{
						search: false,
						paging: false,
						pageSize: 10,
					}}
					localization={tableIntl}
				/>
			)}
		</Box>
	);
}
