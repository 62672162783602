import axios from "axios";
import jwt_decode from "jwt-decode";
import { authProvider } from "./auth";

const prodApi = `https://wftrouting.livetech.site`;
const preProdApi = "https://wftrouting-pre.livetech.site";

function getApiUrl() {
	return process.env.NODE_ENV === "production"
		? process.env.REACT_APP_ENV === "PROD"
			? prodApi
			: preProdApi
		: process.env.REACT_APP_BASE_URL;
}

const baseUrl = process.env.REACT_APP_BASE_URL || "";

const axiosInstance = axios.create({
	baseURL: baseUrl,
});

axiosInstance.interceptors.request.use(requestHandler);
axiosInstance.interceptors.response.use(null, errorHandler);

// REQUEST
async function requestHandler(request) {
	if (request.headers?.skipBearer === false) {
		return request;
	}

	const token = await authProvider.getIdToken();
	const idToken = token.idToken.rawIdToken;

	request.headers.Authorization = idToken;

	return request;
}
// END REQUEST

function errorHandler(error) {
	// let errorMessage = error.response
	// 	? error.response.data.description
	// 	: "Error during retrieve data";

	// showSnackbar("error", errorMessage); toastProvider("title", "error")
	return Promise.reject({ ...error });
}
// END RESPONSE

function decodeJwt(token) {
	const decoded = jwt_decode(token);

	return decoded;
}

export { axiosInstance, decodeJwt, baseUrl };
