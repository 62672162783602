import React, { useState, useEffect } from "react";
import {
	Chip,
	Fab,
	Grid,
	makeStyles,
	Tooltip,
	Typography,
} from "@material-ui/core";
import { blue } from "@material-ui/core/colors";
import ClearAllIcon from "@material-ui/icons/ClearAll";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import SendIcon from "@material-ui/icons/Send";
import { useSnackbar } from "notistack";
import { useForm } from "react-hook-form";
import { Field } from "shared/Field";
import { Loader } from "shared/Loader";
import { getErrorMessage, matchPattern } from "utils";
import { getCfFields } from "./api";

const useStyles = makeStyles((theme) => ({
	footer: {
		paddingTop: theme.spacing(3),
		borderTop: "1px solid rgba(0, 0, 0, 0.12)",
		marginTop: theme.spacing(3),
		marginBottom: theme.spacing(1),
		display: "flex",
		alignItems: "center",
	},
	wrapper: {
		marginLeft: "1rem",
		display: "flex",
	},
	buttonProgress: {
		color: blue[500],
		position: "absolute",
		top: "50%",
		left: "50%",
		marginTop: -12,
		marginLeft: -12,
	},
}));

export function CodiceFiscaleForm({
	cf,
	onChange,
	onSetPolizzaList,
	isInitialLoadingEnabled = true,
}) {
	const classes = useStyles();
	const { register, handleSubmit, errors, reset, watch } = useForm({
		mode: "onChange",
	});
	const [isLoading, setIsLoading] = useState(false);
	const [isCfError, setIsCfError] = useState(false);

	const cfValue = watch("codice_fiscale");

	const [list, setList] = useState([]);

	function onSubmit({ codice_fiscale }) {
		setIsLoading(true);
		setIsCfError(false);
		getCfFields(codice_fiscale)
			.then(({ data }) => {
				setList(data);
				setIsLoading(false);
			})
			.catch((error) => {
				setIsLoading(false);
				setIsCfError(true);
			});
	}

	useEffect(() => {
		onChange(cfValue);
		setIsCfError(false);
	}, [cfValue]);

	useEffect(() => {
		onSetPolizzaList(list);
	}, [list]);

	useEffect(() => {
		if (cf.value && isInitialLoadingEnabled) {
			setIsLoading(true);
			setIsCfError(false);
			getCfFields(cf.value)
				.then(({ data }) => {
					setList(data);
					setIsLoading(false);
				})
				.catch((error) => {
					setIsLoading(false);
					setIsCfError(true);
				});
		}
	}, []);

	return (
		<>
			<Typography variant="h5" className={classes.footer}>
				Ricerca polizze
			</Typography>
			<Loader
				isLoading={isLoading}
				spinnerSize={40}
				loadingLabel={"Attendere prego, ricerca polizze in corso"}
			>
				<form id="cfForm" onSubmit={handleSubmit(onSubmit)}>
					<Grid container spacing={2}>
						<Grid item xs={12} sm={6}>
							<div
								style={{
									display: "flex",
									height: "48px",
								}}
							>
								<Field
									entity={{ ...cf, name: "codice_fiscale" }}
									error={
										errors["codice_fiscale"] ||
										(isCfError
											? { type: "searchPolicyFailed" }
											: "")
									}
									entityRef={register({
										pattern: matchPattern(cf.pattern),
									})}
								/>
								<div className={classes.wrapper}>
									<Tooltip title="Resetta" placement="top">
										<Fab
											elevation={0}
											style={{
												height: "48px",
												width: "48px",
												marginRight: "0.5rem",
											}}
											onClick={() => {
												reset({ codice_fiscale: "" });
												setList([]);
												onChange("");
											}}
										>
											<ClearAllIcon />
										</Fab>
									</Tooltip>
									<Tooltip title="Invia" placement="top">
										{/* SPAN FOR SUPPRESS MATERIAL-UI WARNING */}
										<span>
											<Fab
												type="submit"
												color="primary"
												elevation={0}
												style={{
													height: "48px",
													width: "48px",
												}}
												disabled={!cfValue}
											>
												<SendIcon />
											</Fab>
										</span>
									</Tooltip>
								</div>
							</div>
						</Grid>
						<Grid item xs={12} sm={6} style={{ display: "flex" }}>
							<div
								style={{
									justifyContent: "center",
									alignItems: "center",
								}}
							>
								{list.map((value) => (
									<Chip
										key={value}
										label={value}
										onDelete={() =>
											navigator.clipboard.writeText(value)
										}
										deleteIcon={
											<Tooltip
												title="Clicca per copiare il valore"
												placement="top"
											>
												<FileCopyIcon />
											</Tooltip>
										}
										style={{
											marginRight: "0.6rem",
											marginBottom: "1rem",
										}}
									/>
								))}
							</div>
						</Grid>
					</Grid>
				</form>
			</Loader>
		</>
	);
}
