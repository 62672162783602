import { MsalAuthProvider, LoginType } from "react-aad-msal";

const prodId = "0470e247-9442-467b-a4aa-f6d77c54a36a";
const preProdId = "b99d979d-35e8-47c9-8634-997ab31d3bce";

const config = {
	auth: {
		authority:
			"https://login.microsoftonline.com/75584e34-72c0-4772-b459-a9fe78fec27c",
		clientId: process.env.REACT_APP_ENV === "PROD" ? prodId : preProdId,
		postLogoutRedirectUri: window.location.origin,
		redirectUri: window.location.origin,
		navigateToLoginRequestUrl: true,
	},
	cache: {
		cacheLocation: "localStorage",
	},
};

const authenticationParameters = {
	// scopes: [
	// 	"user.read",
	// 	"https://realelivetech.onmicrosoft.com/reale-wft-mail-routing/user.read",
	// ],
};

// Options
const options = {
	loginType: LoginType.Redirect,
	tokenRefreshUri: window.location.origin,
};

export const authProvider = new MsalAuthProvider(
	config,
	authenticationParameters,
	options
);
