function getRoundedValue(value) {
	return value > 1000000
		? `${Math.round(value * 0.000001 * 10) / 10}m`
		: value > 1000
		? `${Math.round(value * 0.001 * 10) / 10}k`
		: value;
}

function disableEntities(entities, type) {
	const newEntities = entities.map((entity) => {
		if (entity.giuridico) {
			return {
				...entity,
				mandatory: !(type === "giuridica"),
				readonly: type === "giuridica",
			};
		}

		return { ...entity };
	});

	return newEntities;
}

function matchPattern(pattern) {
	let regex = /^/;
	switch (pattern) {
		case "cf_piva":
			regex =
				/^[A-Za-z]{6}[0-9]{2}[A-Za-z]{1}[0-9]{2}[A-Za-z]{1}[0-9]{3}[A-Za-z]{1}$|^[0-9]{11}$/;
			break;
		case "iban":
			regex = /^(it|IT)[0-9]{2}[A-Za-z][0-9]{10}[0-9A-Za-z]{12}$/gm;
			break;
		case "cf":
			regex =
				/^[A-Za-z]{6}[0-9]{2}[A-Za-z]{1}[0-9]{2}[A-Za-z]{1}[0-9]{3}[A-Za-z]{1}$/;
			break;
		default:
			regex = "";
	}
	return regex;
}

function getErrorMessage(error) {
	const errorMessage = error.response?.data.description || "Errore generico";
	return errorMessage;
}

function getBase64(file, callback) {
	const reader = new FileReader();
	reader.readAsDataURL(file);
	reader.onload = function () {
		return callback(reader.result);
	};
}

function formatCellValue(value) {
	if (value) {
		switch (true) {
			case Array.isArray(value):
				return value.join(", ");
			case typeof value === "string":
				return value;
			default:
				return value.toString();
		}
	}
}

export {
	getRoundedValue,
	disableEntities,
	matchPattern,
	getErrorMessage,
	formatCellValue,
	getBase64,
};
