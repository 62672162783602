import React from "react";
import { Box, Button, Grid } from "@material-ui/core";
import PropTypes from "prop-types";
import { FileUpload } from "shared/FileUpload";

export function UploadForm({ onUpload, handleSetFile, file, ...rest }) {
	return (
		<Grid item xs={12} {...rest}>
			<form id="uploadForm" style={{ marginTop: "1rem" }}>
				<Box>
					<FileUpload onUpload={handleSetFile} label={file?.name} />
					{file?.name && (
						<Button
							component="span"
							variant="contained"
							color="secondary"
							size="large"
							onClick={onUpload}
						>
							Carica file
						</Button>
					)}
				</Box>
			</form>
		</Grid>
	);
}

UploadForm.propTypes = {
	onUpload: PropTypes.func.isRequired,
	handleSetFile: PropTypes.func.isRequired,
	file: PropTypes.object,
};
