import React from "react";
import {
	Checkbox,
	FormControlLabel,
	makeStyles,
	TextField,
} from "@material-ui/core";
import Chip from "@material-ui/core/Chip";
import WarningIcon from "@material-ui/icons/Warning";
import Autocomplete from "@material-ui/lab/Autocomplete";
import PropTypes from "prop-types";
import { Controller } from "react-hook-form";

const danger = "#D16405";
const success = "#64D164";
const useStyles = makeStyles((theme) => ({
	header: {
		paddingBottom: theme.spacing(3),
		borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
		marginBottom: theme.spacing(3),
		display: "flex",
		alignItems: "flex-start",
	},
	footer: {
		paddingTop: theme.spacing(3),
		borderTop: "1px solid rgba(0, 0, 0, 0.12)",
		marginTop: theme.spacing(3),
		display: "flex",
		alignItems: "flex-end",
	},
	filename: {
		paddingLeft: "1rem",
	},
	actions: {
		display: "flex",
		justifyContent: "flex-end",
		margin: "-7rem 0 6rem",
	},
	action: {
		marginLeft: "1rem",
		boxShadow: "0 0 0 #000",
		backgroundColor: theme.palette.secondary.main,
	},
	dangerAction: {
		backgroundColor: danger,
	},
	successAction: {
		backgroundColor: success,
	},
	submitButton: {
		minWidth: "240px",
		maxWidth: "240px",
		borderRadius: 0,
		margin: "2rem auto",
	},
	readonly: {
		opacity: 0.5,
		pointerEvents: "none",
	},
}));

export function Field({ entity, entityRef, error }) {
	const classes = useStyles();

	function getFieldType() {
		switch (true) {
			case Array.isArray(entity.value): {
				return (
					<Controller
						defaultValue={entity.value || []}
						rules={{
							validate: (value) =>
								entity.mandatory
									? value.length > 0 || "Campo obbligatorio"
									: true,
						}}
						render={(props, status) => (
							<>
								<Autocomplete
									{...props}
									disabled={entity.readonly}
									multiple
									freeSolo
									options={entity.value}
									defaultValue={entity.value || []}
									renderTags={(value, getTagProps) =>
										value.map((option, index) => (
											<Chip
												key={index}
												size="small"
												variant="outlined"
												label={option}
												{...getTagProps({ index })}
											/>
										))
									}
									renderInput={(params) => (
										<TextField
											{...params}
											label={
												entity.mandatory
													? `${entity.label} *`
													: entity.label
											}
											InputLabelProps={{
												shrink: true,
											}}
											error={
												!!status.invalid &&
												!entity.readonly
											}
											helperText="Inserire un valore e premere Invio per aggiungerlo"
										/>
									)}
									onChange={(_, data, reason) => {
										if (entity.onChange) {
											entity.onChange(
												data,
												reason,
												(response) => {
													if (
														response &&
														response.length > 0
													)
														props.onChange(data);
												}
											);
										} else props.onChange(data);
									}}
								/>
								{!!status.invalid && !entity.readonly && (
									<span
										style={{
											display: "inline-block",
											width: "100%",
											color: "#ff2200",
											fontSize: "12px",
										}}
									>
										<span>{status.invalid.message}</span>
										<span
											style={{
												float: "right",
											}}
										>
											<WarningIcon
												className={
													classes.errorAlertIcon
												}
												fontSize="small"
											/>
										</span>
									</span>
								)}
							</>
						)}
						name={entity.name}
					/>
				);
			}
			case typeof entity.value === "string" ||
				typeof entity.value === "number": {
				return (
					<TextField
						onChange={entity.onChange}
						fullWidth
						error={!!error}
						name={entity.name}
						inputRef={entityRef}
						defaultValue={entity.value || ""}
						label={
							entity.mandatory
								? `${entity.label} *`
								: entity.label
						}
						InputLabelProps={{ shrink: true }}
						inputProps={{ readOnly: entity.readonly }}
						className={entity.readonly ? classes.readonly : ""}
						helperText={
							!!error && (
								<span
									style={{
										display: "inline-block",
										width: "100%",
									}}
								>
									<span>
										{error.type === "required"
											? "Campo obbligatorio"
											: error.type === "pattern"
											? "Il valore inserito non è corretto"
											: error.type ===
											  "searchPolicyFailed"
											? "Ricerca polizze fallito"
											: ""}
									</span>
									<span
										style={{
											float: "right",
										}}
									>
										<WarningIcon
											className={classes.errorAlertIcon}
											fontSize="small"
										/>
									</span>
								</span>
							)
						}
					/>
				);
			}
			case typeof entity.value === "boolean": {
				return (
					<>
						<FormControlLabel
							control={
								<Checkbox
									name={entity.name}
									defaultChecked={entity.value}
									inputRef={entityRef}
								/>
							}
							label={
								entity.mandatory
									? `${entity.label} *`
									: entity.label
							}
						/>
					</>
				);
			}
		}
	}

	return getFieldType();
}

Field.propTypes = {
	entity: PropTypes.shape({
		name: PropTypes.string.isRequired,
		label: PropTypes.string,
		pattern: PropTypes.string,
		mandatory: PropTypes.bool,
		readonly: PropTypes.bool,
		value: PropTypes.any,
	}).isRequired,
	entityRef: PropTypes.any,
	error: PropTypes.object,
};
