import React, { useEffect, useState } from "react";
import { Backdrop, Grid, makeStyles } from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import PropTypes from "prop-types";
import {
	Redirect,
	Route,
	Switch,
	useHistory,
	useLocation,
} from "react-router-dom";
import { Document } from "components/Document";
import { Settings } from "components/Settings/Settings";
import Auth from "shared/Auth";
import { Main } from "shared/Main";
import { Sidebar } from "shared/Sidebar";
import { useRole } from "utils/hooks";
import { Dashboard } from "views/Dashboard";

const useStyles = makeStyles((theme) => ({
	backdrop: {
		zIndex: theme.zIndex.drawer - 1,
	},
}));

export function Layout({ onLogout }) {
	const classes = useStyles();
	const roles = useRole();
	const location = useLocation();
	const history = useHistory();

	const wideScreen = useMediaQuery((theme) => theme.breakpoints.up("sm"));
	const [open, setOpen] = useState(false);

	function handleDrawerOpen() {
		setOpen(true);
	}

	function handleDrawerClose(event) {
		if (
			event.type === "keydown" &&
			(event.key === "Tab" || event.key === "Shift")
		) {
			return;
		}

		setOpen(false);
	}

	useEffect(() => {
		if (roles) {
			const route = location.pathname.replaceAll("/", "");

			if (route === "dashboard") {
				if (roles.isITUser) {
					// If Oper1
					history.push("/dashboard/rid");
				} else {
					history.push("/dashboard/dichiarazione");
				}
			}
		}
	}, [roles]);

	return (
		<>
			<Sidebar
				open={open}
				handleDrawerClose={handleDrawerClose}
				onLogout={onLogout}
			/>

			{!wideScreen && (
				<Backdrop className={classes.backdrop} open={open} />
			)}
			<Main
				open={open}
				handleDrawerOpen={handleDrawerOpen}
				handleDrawerClose={handleDrawerClose}
			>
				<Grid item xs={12}>
					<Switch>
						<Route path="/dashboard">
							<Switch>
								<Route exact path="/dashboard/rid">
									<Auth>
										{(isReadOnly) => {
											return (
												<Dashboard
													isReadOnly={isReadOnly}
													type="rid"
												/>
											);
										}}
									</Auth>
								</Route>
								<Route exact path="/dashboard/dichiarazione">
									<Auth>
										{(isReadOnly) => {
											return (
												<Dashboard
													isReadOnly={isReadOnly}
													type="dichiarazione"
												/>
											);
										}}
									</Auth>
								</Route>
								<Route path="/dashboard/:type/:id">
									<Auth>
										{(isReadOnly) => {
											return (
												<Document
													isReadOnly={isReadOnly}
												/>
											);
										}}
									</Auth>
								</Route>
								<Route path="/dashboard/settings">
									<Settings />
								</Route>
							</Switch>
						</Route>

						<Route path="*">
							<Redirect to="/dashboard" />
						</Route>
					</Switch>
				</Grid>
			</Main>
		</>
	);
}

Layout.propTypes = {
	onLogout: PropTypes.func.isRequired,
};
