import React from "react";
import { Grid, Typography } from "@material-ui/core";

export function Unauthorized() {
	return (
		<>
			<Grid item xs={12}>
				<Typography>
					You are not authorized to view this page
				</Typography>
			</Grid>
		</>
	);
}

Unauthorized.propTypes = {};
