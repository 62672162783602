import React, { useEffect, useState, Fragment } from "react";
import { Box, Grid, Link, Typography, Checkbox } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import PropTypes from "prop-types";
import { getDocumentAttachmentLinkDownload } from "./api";

const useStyles = makeStyles((theme) => ({
	header: {
		paddingBottom: theme.spacing(3),
		borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
		marginBottom: theme.spacing(3),
		display: "flex",
		alignItems: "flex-start",
	},
	footer: {
		paddingTop: theme.spacing(3),
		borderTop: "1px solid rgba(0, 0, 0, 0.12)",
		marginTop: theme.spacing(3),
		marginBottom: theme.spacing(1),
		display: "flex",
		alignItems: "center",
	},
	attachmentsBox: {
		display: "flex",
		padding: "0.5rem",
		fontSize: "0.8125rem",
		alignItems: "center",
	},
	attachmentsIcon: {
		marginRight: theme.spacing(2),
		fontSize: "2rem",
	},
	attachmentIcon: {
		marginRight: "4px",
	},
}));

function EmailBodyParts({ bodyParts }) {
	function createMarkup(part) {
		return { __html: part };
	}

	return (
		<Box>
			{bodyParts.map((part, index) => (
				<Fragment key={index}>
					<details
						style={{
							cursor: "pointer",
							fontSize: "0.9rem",
							margin: "8px 0",
							padding: "8px",
							borderBottom: "1px solid #888",
						}}
					>
						<summary
							style={{
								outline: "none",
								margin: "0 0 0.5rem 1rem",
								alignItems: "center",
								// justifyContent: "space-between",
								paddingTop: "16px",
							}}
						>
							<Typography
								variant="subtitle2"
								style={{ display: "inline-block" }}
							>
								{part["Da:"]}
							</Typography>
							<Typography
								variant="subtitle2"
								style={{
									color: "#888",
									display: "inline-block",
									float: "right",
								}}
							>
								{part["Inviato:"]}
							</Typography>
						</summary>
						<div
							style={{
								fontSize: "0.9rem",
								margin: "24px 16px 16px",
							}}
							dangerouslySetInnerHTML={createMarkup(
								part.text_body
							)}
						/>
					</details>
				</Fragment>
			))}
		</Box>
	);
}

export function Email({ email, id, type, attachmentData, handleFilesChecked }) {
	const classes = useStyles();

	return (
		<>
			<Grid item xs={12}>
				<Typography variant="h5" className={classes.footer}>
					E-mail originale
				</Typography>
			</Grid>
			<Grid item xs={12}>
				{email.date.value && (
					<Typography variant="subtitle1">
						Data invio: <strong>{email.date.value}</strong>
					</Typography>
				)}

				{email.sender.value && (
					<Typography variant="subtitle1">
						Mittente:{" "}
						<strong>{email.sender.value.toString()}</strong>
					</Typography>
				)}

				{email.receivers.value && (
					<Typography variant="subtitle1">
						Destinatario:{" "}
						<strong>{email.receivers.value.toString()}</strong>
					</Typography>
				)}

				{email.subject.value && (
					<Typography variant="subtitle1">
						Oggetto: <strong>{email.subject.value}</strong>
					</Typography>
				)}

				<Typography variant="subtitle1" className={classes.footer}>
					<Box>
						<AttachFileIcon className={classes.attachmentsIcon} />
					</Box>
					<Box style={{ display: "flex", flexWrap: "wrap" }}>
						{attachmentData?.length > 0
							? attachmentData.map((attachment, index) => (
									<Box
										key={index}
										className={classes.attachmentsBox}
									>
										{type === "dichiarazione" && (
											<Checkbox
												checked={attachment.flag_upl}
												disabled={!attachment.editable}
												onChange={(e) =>
													handleFilesChecked(
														attachment,
														e.target.checked
													)
												}
											/>
										)}
										<Typography variant="body2">
											<Link
												style={{ cursor: "pointer" }}
												onClick={() => getDocumentAttachmentLinkDownload(
													id,
													type,
													attachment.content,
													attachment.filename
												)}
											>
												{attachment.filename.replace(
													"^",
													"."
												)}
											</Link>
										</Typography>
									</Box>
							  ))
							: "Email senza allegati"}
					</Box>
				</Typography>
				{email.body.value && (
					<>
						<Typography
							variant="subtitle1"
							className={classes.footer}
							style={{ margin: "16px" }}
						>
							<strong>Comunicazioni con il cliente</strong>
						</Typography>

						<EmailBodyParts bodyParts={email.body.value} />
					</>
				)}
			</Grid>
		</>
	);
}

Email.propTypes = {
	email: PropTypes.shape({
		date: PropTypes.shape({
			value: PropTypes.string,
			label: PropTypes.string,
		}),
		sender: PropTypes.shape({
			value: PropTypes.string,
			label: PropTypes.string,
		}),
		receivers: PropTypes.shape({
			value: PropTypes.string,
			label: PropTypes.string,
		}),
		subject: PropTypes.shape({
			value: PropTypes.string,
			label: PropTypes.string,
		}),
		atts: PropTypes.shape({
			value: PropTypes.arrayOf(PropTypes.any),
			label: PropTypes.string,
		}),
		body: PropTypes.shape({
			value: PropTypes.arrayOf(PropTypes.object),
			label: PropTypes.string,
		}),
	}).isRequired,
	id: PropTypes.string.isRequired,
};
