import React, { useEffect, useState } from "react";
import {
	Box,
	CircularProgress,
	Grid,
	Paper,
	Typography,
	Link,
	Select,
	MenuItem,
	FormControl,
	IconButton,
	Divider,
	Tooltip,
	Fab,
	InputLabel,
	TextField,
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Button,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import {Restore, SwapHorizOutlined} from "@material-ui/icons";
import ArrowBack from "@material-ui/icons/ArrowBack";
import CheckIcon from "@material-ui/icons/Check";
import ClearIcon from "@material-ui/icons/Clear";

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import SaveIcon from "@material-ui/icons/Save";
import Alert from "@material-ui/lab/Alert";
import clsx from "clsx";
import moment from "moment";
import { useSnackbar } from "notistack";
import PropTypes from "prop-types";
import { FormProvider, useForm } from "react-hook-form";
import { useHistory, useParams } from "react-router-dom";
import { ClientTypeRadio } from "shared/ClientTypeRadio";
import {disableEntities, getBase64, getErrorMessage, matchPattern} from "utils";
import { authProvider } from "../../services/auth";
import { Loader } from "../../shared/Loader";
import {
	approveDocument,
	closeDocument,
	deleteAttachmentDocumentData,
	getAttachmentData,
	deleteNote,
	getDocumentData,
	getDocumentEntities,
	getHiddenFields,
	getNoteData,
	getSublabels,
	modifyNote,
	reElaborateDocument,
	saveNoteData,
	updateDocument,
	uploadAttachmentDocumentData,
	uploadFileData, getDocumentDataIsaac, closePraticaIsaac, changeUserPratica,
} from "./api";
import { CodiceFiscaleForm } from "./CodiceFiscaleForm";
import { Email } from "./Email";
import { EntitiesForm } from "./EntitiesForm";
import { UploadAttachmentForm } from "./UploadAttachmentForm";
import { UploadForm } from "./UploadForm";
import {Field} from "../../shared/Field";

const danger = "#D16405";
const success = "#64D164";
const info = "#00D1D1";

const useStyles = makeStyles((theme) => ({
	paper: {
		height: "100%",
		display: "flex",
		textAlign: "center",
		alignItems: "center",
		transition: "all 0.3s",
		justifyContent: "center",
		padding: theme.spacing(2),
		cursor: "pointer",
		color: theme.palette.text.secondary,
		"&:hover": {
			color: "black",
			background: "transparent",
		},
	},
	header: {
		paddingBottom: "2rem",
		borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
		marginBottom: theme.spacing(3),
		display: "flex",
		flexWrap: "wrap",
		flexDirection: "column",
		justifyContent: "space-between",
	},
	footer: {
		paddingTop: theme.spacing(3),
		borderTop: "1px solid rgba(0, 0, 0, 0.12)",
		marginTop: theme.spacing(3),
		display: "flex",
		alignItems: "flex-end",
	},
	errorMessage: {
		color: danger,
		textAlign: "center",
		marginBottom: "4rem",
	},
	successMessage: {
		textAlign: "center",
	},
	formControl: {
		marginBottom: theme.spacing(2),
		width: "100%",
	},
	selectControl: {
		margin: "0 0 1rem 0",
		minWidth: 240,
		textAlign: "left",
	},
	titleSelect: {
		display: "flex",
		alignItems: "center",
	},
	errorAlertIcon: {
		color: danger,
		fontSize: 16,
	},
	uploadButtonWrapper: {
		margin: "1rem 0",
		display: "flex",
		alignItems: "center",
	},
	uploadButton: {
		margin: "1rem 0",
		borderRadius: 0,
		minWidth: 240,
	},
	fileUploaded: {
		marginRight: "1rem",
		color: "#888888",
	},
	toolbar: {
		paddingBottom: "1rem",
	},
	date: {
		color: "#888888",
		display: "flex",
		alignItems: "center",
		justifyContent: "flex-start",
		marginBottom: "1rem",
		flexDirection: "row-reverse",
	},
	actions: {
		display: "flex",
		justifyContent: "flex-end",
		margin: "2rem 0 1rem",
	},
	action: {
		marginLeft: "1rem",
		boxShadow: "0 0 0 #000",
		backgroundColor: theme.palette.secondary.main,
	},
	dangerAction: {
		backgroundColor: danger,
	},
	successAction: {
		backgroundColor: success,
	},
	manualAction: {
		backgroundColor: info,
	},
	submitButton: {
		minWidth: "240px",
		maxWidth: "240px",
		borderRadius: 0,
		margin: "2rem auto",
	},
	select: {
		"& ul": {
			padding: 0,
		},
		"& li": {
			padding: "0.75rem",
			fontSize: 14,
		},
	},
	note: {
		width: "100%",
		display: "flex",
		border: "1px solid",
		borderRadius: "0.4rem",
		borderColor: "transparent",
		backgroundColor: "#EEEEEE",
		padding: "0.9rem",
		justifyContent: "space-between",
		textAlign: "justify",
	},
}));

moment.locale("it");

export function Document({ isReadOnly }) {
	const classes = useStyles();
	const { enqueueSnackbar } = useSnackbar();
	const history = useHistory();
	const { id, type } = useParams();
	const methods = useForm({ mode: "onBlur" });

	const [document, setDocument] = useState(null);
	const [documentDataIsaac, setDocumentDataIsaac] = useState(null);
	const [entities, setEntities] = useState(null);
	const [loading, setLoading] = useState(true);
	const [attachmentFormLoading, setAttachmentFormLoading] = useState(false);
	const [formLoading, setFormLoading] = useState(true);
	const [showHideButton, setShowHideButton] = useState(true);
	const [file, setFile] = useState(null);
	const [documentAttachmentFiles, setDocumentAttachmentFiles] = useState([]);
	const [
		retrievedDocumentAttachmentFiles,
		setRetrievedDocumentAttachmentFiles,
	] = useState([]);
	const [emailAttachmentFiles, setEmailAttachmentFiles] = useState([]);
	const [label, setLabel] = useState("");
	const [isUnclassifiedDocument, setIsUnclassifiedDocument] = useState(false);
	const [subLabel, setSublabel] = useState("");
	const [subLabels, setSublabels] = useState(null);
	const [codiceFiscale, setCodiceFiscale] = useState(null);
	const [esecutoreFields, setEsecutoreFields] = useState(null);
	const [flagEsecutore, setFlagEsecutore] = useState(null);
	const [polizzaList, setPolizzaList] = useState(null);
	const [retrievedNotes, setRetrievedNotes] = useState([]);
	const [expandNote, setExpandNote] = useState(false);
	const [loaderMessage, setLoaderMessage] = useState(null);
	const [modifyLastNoteMode, setModifyLastNoteMode] = useState(false);
	const [lastNote, setLastNote] = useState({});

	const username = authProvider.getAccount()?.name;
	const [documentNotes, setDocumentNotes] = useState({
		author: "",
		text: "",
		date: null,
	});

	function returnToTable() {
		history.goBack();
		localStorage.setItem("id", id);
	}

	useEffect(() => {
		if (id) {
			getDocumentData(id, type)
				.then(({ data }) => {
					setDocument(data);

					if (data.note) {
						setDocumentNotes(data.note);
					}

					if (data.overview.provenienza.value === "Isaac") {
						getDocumentDataIsaac(id, type).then(({ data }) => {
							setDocumentDataIsaac(data);
						});
					}

					setLoading(false);

					if (data.email.codice_fiscale?.value) {
						setCodiceFiscale(data.email.codice_fiscale.value);
					}

					if (data.overview?.label.value) {
						setLabel(data.overview.label.value);
						setSublabel(data.overview.sub_label.value);

						if (data.overview.sub_label.value) {
							getSublabels(data.overview.label.value, type).then(
								({ data }) => {
									setSublabels(data);
								}
							);

							getEntities(
								id,
								data.overview.compagnia.value,
								data.overview.label.value,
								data.overview.sub_label.value,
								data.overview.client_type
							);
						} else {
							setEntities([]);
							setFormLoading(false);
						}
					} else {
						setEntities([]);
						setFormLoading(false);
					}
					setIsUnclassifiedDocument(
						data.overview?.status?.value !== "d_no_label"
					);
					if (data.overview?.status?.value !== "d_no_label")
						getDocumentAttachment(id, type);
				})
				.catch((error) => {
					returnToTable();
					enqueueSnackbar(getErrorMessage(error), {
						variant: "error",
					});
				});
			getDocumentNotes(id, type);
		}
	}, [id]);

	function getDocumentNotes(id, type) {
		getNoteData(id, type)
			.then((notes) => {
				setRetrievedNotes(notes.data);
				setLastNote(
					notes.data && notes.data.length > 0 ? notes.data[0] : {}
				);
			})
			.catch((error) =>
				enqueueSnackbar(getErrorMessage(error), {
					variant: "error",
				})
			);
	}

	function getDocumentAttachment(id, type) {
		getAttachmentData(id, type)
			.then((attachments) => {
				setRetrievedDocumentAttachmentFiles(
					attachments.data.file_lista_allegati
				);
				setEmailAttachmentFiles(attachments.data.file_lista_email);
			})
			.catch((error) =>
				enqueueSnackbar(getErrorMessage(error), {
					variant: "error",
				})
			);
	}

	useEffect(() => {
		if (entities && polizzaList && polizzaList.length > 0) {
			const newEntities = [...entities];
			const numPolizza = newEntities.filter(
				(ent) => ent.name === "NUMERO POLIZZA"
			)[0];

			if (numPolizza.value.length === 0) {
				newEntities.forEach((ent) => {
					if (
						ent.name === "NUMERO POLIZZA" &&
						ent.value.length === 0
					) {
						ent.value = polizzaList;
					}
				});
				setEntities(newEntities);
			}
		}
	}, [entities]);

	function handleDocumentClose() {
		closeDocument(id, type)
			.then(() => {
				returnToTable();
				enqueueSnackbar("Richiesta chiusa correttamente", {
					variant: "success",
				});
			})
			.catch((error) => {
				enqueueSnackbar(getErrorMessage(error), {
					variant: "error",
				});
			});
	}

	function closePratica() {
		closePraticaIsaac(id, type)
			.then((response) => {
				returnToTable();
				enqueueSnackbar(response?.data, {
					variant: "success",
				});
			})
			.catch((error) => {
				enqueueSnackbar(getErrorMessage(error), {
					variant: "error",
				});
			});
	}

	function changeUser() {
		setLoading(true);
		changeUserPratica(id, type, {user_email: authProvider.getAccount()?.userName})
			.then((response) => {
				setLoading(false);
				enqueueSnackbar(response?.data, {
					variant: "success",
				});
			})
			.catch((error) => {
				setLoading(false);
				enqueueSnackbar(getErrorMessage(error), {
					variant: "error",
				});
			});
	}

	function handleDocumentAttachmentUpload(attachment, callback) {
		setAttachmentFormLoading(true);
		uploadAttachmentDocumentData(id, type, attachment)
			.then(() => {
				setDocumentAttachmentFiles([]);
				getDocumentAttachment(id, type);
				setAttachmentFormLoading(false);
				callback();
			})
			.catch((error) => {
				setDocumentAttachmentFiles([]);
				getDocumentAttachment(id, type);
				setAttachmentFormLoading(false);
				enqueueSnackbar(getErrorMessage(error), {
					variant: "error",
				});
			});
	}

	function handleDocumentSave() {
		let newData = { ...methods.getValues() };

		for (const entity in newData) {
			if (newData[entity] === null || newData[entity] === "") {
				delete newData[entity];
			}
		}

		const toSend = {
			compagnia: document.overview.compagnia.value,
			label,
			sub_label: subLabel,
			client_type: document.overview.client_type,
			contraente: document.overview.contraente,
			entities: newData || null,
			file_lista_allegati: retrievedDocumentAttachmentFiles,
			file_lista_email: emailAttachmentFiles,
		};

		if (codiceFiscale) {
			toSend.codice_fiscale = codiceFiscale;
		}

		updateDocument(id, type, toSend)
			.then(() => {
				enqueueSnackbar("Richiesta aggiornata correttamente", {
					variant: "success",
				});
			})
			.catch((error) => {
				enqueueSnackbar(getErrorMessage(error), {
					variant: "error",
				});
			});
	}

	function handleLoadHiddenFields() {
		// QUANDO VOGLIO NASCONDERE LE ENTITA'
		if (flagEsecutore) {
			const names = esecutoreFields.map((field) => field.name);

			// FILTRO LE ENTITA' ESECUTORE
			const newEntities = entities.filter(
				(entity) => !names.includes(entity.name)
			);

			setEntities(newEntities);
			setFlagEsecutore(false);
		} else {
			setFormLoading(true);

			const updatedForm = methods.getValues();
			let uptadedEntities = [...entities];

			Object.entries(updatedForm).map(([key, value]) => {
				const index = uptadedEntities.findIndex(
					(field) => field.name === key
				);

				uptadedEntities[index].value = value;
			});

			const toSend = {
				label,
				sub_label: subLabel,
				compagnia: document.overview.compagnia.value,
			};

			getHiddenFields(id, type, toSend)
				.then(({ data }) => {
					setEsecutoreFields(data);
					setEntities([...uptadedEntities, ...data]);
					setFlagEsecutore(true);
					// setShowHideButton(false);
					setFormLoading(false);
				})
				.catch((error) => {
					setFormLoading(false);
					enqueueSnackbar(getErrorMessage(error), {
						variant: "error",
					});
				});
		}
	}

	function isAttachmentExtensionAccepted(extension) {
		return (
			extension === "pdf" ||
			extension === "docx"
		);
	}

	function handleDocumentReElaborate() {
		const toSend = {
			label,
			sub_label: subLabel,
			compagnia: document.overview.compagnia.value,
		};

		reElaborateDocument(id, type, toSend)
			.then(() => {
				returnToTable();
				enqueueSnackbar("Richiesta rielaborata correttamente", {
					variant: "success",
				});
			})
			.catch((error) => {
				enqueueSnackbar(getErrorMessage(error), {
					variant: "error",
				});
			});
	}

	function handleDocumentApprove(data) {
		let newData = { ...data };

		for (const entity in newData) {
			if (newData[entity] === null || newData[entity] === "") {
				delete newData[entity];
			}
		}

		const toSend = {
			label,
			sub_label: subLabel,
			client_type: document.overview.client_type,
			sender: document.overview.sender.value,
			compagnia: document.overview.compagnia.value,
			entities: newData,
			file_lista_allegati: [
				...retrievedDocumentAttachmentFiles.map((file) => ({
					...file,
					editable: false,
				})),
			],
			file_lista_email: emailAttachmentFiles,
		};

		if (codiceFiscale) {
			toSend.codice_fiscale = codiceFiscale;
		}

		setLoaderMessage("Attendere prego, stiamo approvando la tua richiesta");
		setLoading(true);

		approveDocument(id, type, toSend)
			.then(() => {
				setLoading(false);
				returnToTable();
				enqueueSnackbar("Richiesta approvata correttamente", {
					variant: "success",
				});
			})
			.catch((error) => {
				setLoading(false);
				enqueueSnackbar(getErrorMessage(error), {
					variant: "error",
				});
			});
	}

	function handleClientTypeChange(value) {
		const updatedOverview = { ...document.overview, client_type: value };
		setDocument({ ...document, overview: updatedOverview });

		setEntities(disableEntities(entities, value));
	}

	function handleCompagniaChange(event) {
		const newCompagnia = event.target.value;
		setDocument({
			...document,
			overview: {
				...document.overview,
				compagnia: {
					...document.overview.compagnia,
					value: newCompagnia,
				},
			},
		});

		if (document.overview.status.value !== "d_no_label") {
			setShowHideButton(true);
			getEntities(id, newCompagnia, label, subLabel);
		} else {
			setShowHideButton(false);
			setEntities([]);
		}
	}

	function getNoteBox(author, text, date) {
		return (
			<Box className={classes.note}>
				<Box>
					<Box>
						<span style={{ fontWeight: "500" }}>{author}</span> ha
						scritto:
					</Box>
					<Box
						style={{
							fontWeight: "bold",
							marginTop: "5px",
							fontSize: "0.9rem",
							whiteSpace: "pre-wrap",
						}}
					>
						{text}
					</Box>
				</Box>
				<Box style={{ whiteSpace: "nowrap" }}>
					{moment(date).format("DD/MM/YYYY H:mm:ss")}
				</Box>
			</Box>
		);
	}

	function handleLabelChange(event) {
		setLabel(null);
		setSublabel("");
		setSublabels(null);
		const newLabel = event.target.value;
		setLabel(newLabel);

		getSublabels(newLabel, type).then(({ data }) => {
			setSublabels(data);
		});

		if (
			newLabel !== "DISDETTA" &&
			document.overview.status.value !== "d_no_label"
		) {
			setShowHideButton(true);

			getEntities(
				id,
				document.overview.compagnia.value,
				newLabel,
				subLabel
			);
		} else {
			setShowHideButton(false);
			setEntities([]);
		}
	}

	function handleSubLabelChange(event) {
		const newSubLabel = event.target.value;
		setSublabel(newSubLabel);

		if (document.overview.status.value !== "d_no_label") {
			setShowHideButton(true);

			getEntities(
				id,
				document.overview.compagnia.value,
				label,
				newSubLabel
			);
		} else {
			setShowHideButton(false);
			setEntities([]);
		}
	}

	function getEntities(id, compagnia, label, sub_label, client_type) {
		setFormLoading(true);
		let toSend = {
			label,
			sub_label,
		};

		if (compagnia) {
			toSend.compagnia = compagnia;
		}

		getDocumentEntities(id, type, toSend)
			.then(({ data }) => {
				setEntities(
					disableEntities(
						data.entities || [],
						client_type || document?.overview.client_type
					)
				);
				setFlagEsecutore(data.all);

				if (data.all) {
					setShowHideButton(false);
				}
				setFormLoading(false);
			})
			.catch((error) => {
				setFormLoading(false);
				enqueueSnackbar(getErrorMessage(error), {
					variant: "error",
				});
			});
	}

	function checkOrUncheckAllFile(attachmentFiles, isChecked) {
		attachmentFiles.forEach((file) => {
			if (file.editable) file.flag_upl = isChecked;
		});
	}

	function handleFileUpload() {
		setFormLoading(true);
		const form = new FormData();
		form.append("file", file);

		uploadFileData(id, type, document.overview.compagnia.value, form)
			.then(({ data }) => {
				setLabel(data.label);
				setSublabel(data.sub_label);

				setEntities(
					disableEntities(
						data.entities,
						document.overview.client_type
					)
				);
				setDocument({
					...document,
					overview: {
						...document.overview,
						description: data.description,
						contraente: data.contraente,
					},
				});

				setShowHideButton(false);
				setFormLoading(false);
			})
			.catch((error) => {
				setFormLoading(false);
				enqueueSnackbar(getErrorMessage(error), {
					variant: "error",
				});
			});
	}

	function isSaveButtonEnabled() {
		return (
			document.overview.status.value !== "d_no_label" &&
			(label === "DICHIARAZIONE" ||
				(label === "RID" &&
					document.overview.status.value !== "d_generic")) &&
			label === "DICHIARAZIONE" &&
			document.overview.status.value !== "iter_erroneo" &&
			document.overview.status.value !== "d_generic_d" &&
			document.overview.status.value !== "inactive" &&
			document.overview.status.value !== "intermediario_no_att" &&
			label === document.overview.label.value
		);
	}

	function isUploadFormEnabled() {
		return (
			document.overview.provenienza.value === "Altro canale" &&
			document.overview.status.value !== "d_generic" &&
			document.overview.status.value !== "inactive" &&
			label === "RID"
		);
	}

	function isClientTypeRadioEnabled() {
		return document.overview.client_type && label === "RID";
	}

	function isCompanyChangeEnabled() {
		return (
			document.overview.label.value === label &&
			![
				"dati_mancanti",
				"intermediario_no_att",
				"verifica_delega",
				"n_pol_empty",
				"d_no_label",
				"sentvalidation_d",
				"deleted",
			].includes(document.overview.status.value)
		);
	}

	function isAllAttachmentFileChecked() {
		let isAllFileChecked = true;
		for (let file of [
			...documentAttachmentFiles,
			...retrievedDocumentAttachmentFiles,
			...emailAttachmentFiles,
		]) {
			if (file.editable && !file.flag_upl) {
				isAllFileChecked = false;
				break;
			}
		}
		return isAllFileChecked;
	}

	function isOneAttachmentFileCheckable() {
		let isCheckable = false;
		for (let file of [
			...documentAttachmentFiles,
			...retrievedDocumentAttachmentFiles,
			...emailAttachmentFiles,
		]) {
			let extension = file.filename
				.replace("##", ".")
				.toLowerCase()
				.split(".")
				.pop();
			if (file.editable && isAttachmentExtensionAccepted(extension)) {
				isCheckable = true;
				break;
			}
		}
		return isCheckable;
	}

	function handleRemoveFile(attachment) {
		setAttachmentFormLoading(true);
		deleteAttachmentDocumentData(id, type, [attachment])
			.then(() => {
				setDocumentAttachmentFiles(
					documentAttachmentFiles.filter(
						(file) => file.content !== attachment.content
					)
				);
				getDocumentAttachment(id, type);
				setAttachmentFormLoading(false);
				return enqueueSnackbar(
					`File ${attachment.filename} rimosso con successo`,
					{
						variant: "success",
					}
				);
			})
			.catch((error) => {
				setAttachmentFormLoading(false);
				enqueueSnackbar(getErrorMessage(error), {
					variant: "error",
				});
			});
	}

	function handleSelectDeselectAllFile() {
		const isToCheck = !isAllAttachmentFileChecked();
		let newDocumentAttachmentFiles = [...documentAttachmentFiles];
		let newRetrievedDocumentAttachmentFiles = [
			...retrievedDocumentAttachmentFiles,
		];
		let newEmailAttachmentFiles = [...emailAttachmentFiles];
		checkOrUncheckAllFile(newDocumentAttachmentFiles, isToCheck);
		checkOrUncheckAllFile(newRetrievedDocumentAttachmentFiles, isToCheck);
		checkOrUncheckAllFile(newEmailAttachmentFiles, isToCheck);
		setDocumentAttachmentFiles(newDocumentAttachmentFiles);
		setRetrievedDocumentAttachmentFiles(
			newRetrievedDocumentAttachmentFiles
		);
		setEmailAttachmentFiles(newEmailAttachmentFiles);
	}

	return (
		<Grid container justify="center" spacing={2}>
			<Grid item xs={12}>
				<Paper
					style={{
						minHeight: "50vh",
						padding: "2rem 2rem 4rem",
					}}
				>
					{loading ? (
						<Box p={15}>
							<Loader
								isLoading={loading}
								spinnerSize={75}
								loadingLabel={loaderMessage}
							/>
						</Box>
					) : (
						<>
							<Box
								style={{
									display: "flex",
									justifyContent: "space-between",
								}}
							>
								<Box>
									<Tooltip title="Torna indietro">
										<IconButton
											aria-label="delete"
											style={{ padding: 0 }}
											onClick={returnToTable}
										>
											<ArrowBack />
										</IconButton>
									</Tooltip>
								</Box>
							</Box>
							<Divider style={{ margin: "1rem 0" }} />
							<Grid
								container
								spacing={2}
								className={classes.toolbar}
							>
								<Grid
									item
									xs={12}
									sm={6}
									className={classes.header}
								>
									{document.overview.status.label && (
										<Box>
											<Typography
												variant="h4"
												className={classes.titleSelect}
												style={{ marginBottom: "3rem" }}
											>
												Stato richiesta:{" "}
												{document.overview.status.label}
											</Typography>
										</Box>
									)}

									{document.overview.provenienza.value ===
										"Piteco" && (
										<Box>
											<Typography
												variant="h6"
												className={classes.titleSelect}
											>
												{
													document.overview
														.provenienza.label
												}
												:{" "}
												{
													document.overview
														.provenienza.value
												}
											</Typography>
										</Box>
									)}

									{(document.overview.status.value ===
										"isaac" ||
										document.overview.status.value ===
											"dati_mancanti") &&
										document.overview.isaac_link.value && (
											<Box>
												<Typography variant="h6">
													<Link
														href={
															document.overview
																.isaac_link
																.value
														}
														color="primary"
														target="_blank"
													>
														{
															document.overview
																.isaac_link
																.label
														}
													</Link>
												</Typography>
											</Box>
										)}
									{document.overview.origine.value && (
										<Box>
											<Typography
												variant="h6"
												style={{
													color: "#AAA",
													fontWeight: 400,
													margin: "1rem 0 0",
												}}
											>
												{
													document.overview.origine
														.label
												}
												:{" "}
												{
													document.overview.origine
														.value
												}
											</Typography>
										</Box>
									)}

									{document.overview.sent_validation_date
										.label && (
										<Box>
											<Typography
												variant="h6"
												style={{
													color: "#AAA",
													fontWeight: 400,
													margin: "1rem 0 0.5rem",
												}}
											>
												{
													document.overview
														.sent_validation_date
														.label
												}
												:{" "}
												{
													document.overview
														.sent_validation_date
														.value
												}
											</Typography>
										</Box>
									)}

									{document.overview.provenienza !==
										"Piteco" && (
										<>
											{document.overview.contraente && (
												<Box
													style={{
														margin: "1rem 0",
													}}
												>
													<Alert
														variant="outlined"
														severity="info"
													>
														{
															document.overview
																.contraente
														}
													</Alert>
												</Box>
											)}
										</>
									)}
								</Grid>

								<Grid
									item
									xs={12}
									sm={6}
									style={{ alignItems: "flex-end" }}
									className={classes.header}
								>
									<Box>
										{isCompanyChangeEnabled() &&
											!(
												label === "DICHIARAZIONE" &&
												document.overview.status
													.value === "d_generic"
											) && (
												<Box>
													<FormControl
														className={
															classes.selectControl
														}
														disabled={
															isReadOnly ||
															document.overview
																.status
																.value ===
																"inactive"
														}
													>
														<InputLabel shrink>
															Compagnia
														</InputLabel>
														<Select
															value={
																document
																	.overview
																	.compagnia
																	.value
															}
															onChange={
																handleCompagniaChange
															}
															MenuProps={{
																classes: {
																	paper: classes.select,
																},
															}}
														>
															<MenuItem value="RMA">
																Reale Mutua
																assicurazioni
															</MenuItem>
															<MenuItem value="ITA">
																Italiana
																assicurazioni
															</MenuItem>
														</Select>
													</FormControl>
												</Box>
											)}

										<Box>
											<FormControl
												className={
													classes.selectControl
												}
												disabled={
													//isReadOnly ||
													document.overview.status
														.value === "inactive"
												}
											>
												<InputLabel shrink>
													Classificazione
												</InputLabel>
												<Select
													value={label}
													onChange={handleLabelChange}
													MenuProps={{
														classes: {
															paper: classes.select,
														},
													}}
												>
													{document.overview
														.classificazioni &&
														document.overview.classificazioni.map(
															(element) => (
																<MenuItem
																	key={
																		element.value
																	}
																	value={
																		element.value
																	}
																>
																	{
																		element.label
																	}
																</MenuItem>
															)
														)}
												</Select>
												{/* )} */}
											</FormControl>
										</Box>

										{label !== "DISDETTA" && subLabels && (
											<Box>
												<FormControl
													className={
														classes.selectControl
													}
													disabled={
														// isReadOnly ||
														document.overview.status
															.value ===
														"inactive"
													}
												>
													<InputLabel shrink>
														Tipologia
													</InputLabel>
													<Select
														value={subLabel}
														onChange={
															handleSubLabelChange
														}
														MenuProps={{
															classes: {
																paper: classes.select,
															},
														}}
													>
														{subLabels &&
															subLabels.map(
																(s, i) => (
																	<MenuItem
																		key={i}
																		value={
																			s.value
																		}
																	>
																		{
																			s.label
																		}
																	</MenuItem>
																)
															)}
													</Select>
												</FormControl>
											</Box>
										)}
									</Box>
									<Box
										id="documentActionsToolbar"
										className={classes.actions}
									>
										{!isReadOnly &&
											document.overview.status.value !==
												"deleted" &&
											document.overview.status.value !==
												"iter_erroneo" &&
											document.overview.status.value !==
											"blocco_vis" && (
												<Tooltip title="Chiudi richesta">
													<Fab
														elevation={0}
														onClick={
															handleDocumentClose
														}
														className={clsx(
															classes.action,
															classes.dangerAction
														)}
													>
														<ClearIcon />
													</Fab>
												</Tooltip>
											)}
										{!isReadOnly &&
											isSaveButtonEnabled() &&
											document.overview.status.value !==
												"deleted" &&
											document.overview.status.value !==
												"status_12_completed" &&
											document.overview.status.value !==
											"blocco_vis" && (
												<Tooltip title="Salva richiesta senza passare per l'RPA">
													<Fab
														elevation={0}
														onClick={
															handleDocumentSave
														}
														className={clsx(
															classes.action
														)}
													>
														<SaveIcon />
													</Fab>
												</Tooltip>
											)}

										{!isReadOnly &&
											((label === "DICHIARAZIONE" &&
												document.overview.status
													.value !==
													"iter_erroneo") ||
												label === "DICHIARAZIONE" ||
												(label === "RID" &&
													document.overview.status
														.value !==
														"d_generic")) &&
											document.overview.status.value !==
												"d_generic_d" &&
											document.overview.status.value !==
												"inactive" &&
											document.overview.status.value !==
												"status_12_completed" &&
											document.overview.status.value !==
											"blocco_vis" && (
												<>
													{[
														"d_no_label",
														"deleted",
													].includes(
														document.overview.status
															.value
													) ||
													label !==
														document.overview.label
															.value ? (
														<Tooltip title="Rielabora richiesta">
															<Fab
																elevation={0}
																onClick={
																	handleDocumentReElaborate
																}
																className={clsx(
																	classes.action,
																	classes.manualAction
																)}
															>
																<Restore />
															</Fab>
														</Tooltip>
													) : (
														<>
															{document.overview
																.status
																.value !==
																"intermediario_no_att" &&
																document
																	.overview
																	.status
																	.value !==
																	"status_12_wait_notify" &&
																document
																	.overview
																	.status
																	.value !==
																	"pol_out_of_range" &&
																document.overview.status.value !==
																"blocco_vis" && (
																	<Tooltip title="Approva richiesta">
																		<span>
																			<Fab
																				elevation={
																					0
																				}
																				className={clsx(
																					classes.action,
																					classes.successAction
																				)}
																				type="submit"
																				form="form"
																				disabled={
																					(!label &&
																						!subLabel) ||
																					document
																						.overview
																						.status
																						.value ===
																						"completed" ||
																					(isCompanyChangeEnabled() &&
																						!(
																							label ===
																								"DICHIARAZIONE" &&
																							document
																								.overview
																								.status
																								.value ===
																								"d_generic"
																						) &&
																						!document
																							.overview
																							.compagnia
																							.value)
																				}
																			>
																				<CheckIcon />
																			</Fab>
																		</span>
																	</Tooltip>
																)}
														</>
													)}
												</>
										)}
										{
											document.overview.status.value ===
											"blocco_vis" && (
												<div>
													<Tooltip title="Passa ad utente">
																	<span>
																	<Fab
																		elevation={
																			0
																		}
																		className={clsx(
																			classes.action,
																			classes.manualAction
																		)}
																		onClick={changeUser}
																	>
																	<SwapHorizOutlined/>
																	</Fab>
																	</span>
													</Tooltip>
													<Tooltip title="Approva richiesta">
																		<span>
																			<Fab
																				elevation={
																					0
																				}
																				className={clsx(
																					classes.action,
																					classes.successAction
																				)}
																				onClick={closePratica}
																			>
																				<CheckIcon/>
																			</Fab>
																		</span>
													</Tooltip>
												</div>
											)}
									</Box>
								</Grid>
							</Grid>
							{document.overview.description &&
								document.overview.description.length > 0 && (
									<Grid item xs={12}>
										<Typography
											variant="subtitle2"
											className={clsx(
												classes.errorMessage,
												classes.header
											)}
										>
											{document.overview.description.map(
												(error, i) => (
													<Alert
														key={i}
														severity="error"
														style={{
															margin: "0 auto 0.5rem",
															width: "100%",
														}}
													>
														{error}
													</Alert>
												)
											)}
										</Typography>
									</Grid>
								)}
							{label !== document.overview.label.value ? (
								<Alert severity="warning">
									Richiesta non gestibile attraverso la
									dashboard
								</Alert>
							) : (
								<>
									{formLoading ? (
										<Grid
											container
											direction="row"
											justify="center"
										>
											<Box p={10}>
												<CircularProgress size={75} />
											</Box>
										</Grid>
									) : (
										<>
											{document.overview.status.value !==
												"d_no_label" && (
												<>
													{label ===
														document.overview.label
															.value &&
														((label === "RID" &&
															document.overview
																.status
																.value !==
																"d_generic") ||
															label ===
																"DICHIARAZIONE") && (
															<>
																{isClientTypeRadioEnabled() && (
																	<ClientTypeRadio
																		value={
																			document
																				.overview
																				.client_type
																		}
																		onChange={
																			handleClientTypeChange
																		}
																		disabled={
																			isReadOnly ||
																			document
																				.overview
																				.status
																				.value ===
																				"inactive"
																		}
																	/>
																)}

																<FormProvider
																	{...methods}
																>
																	<EntitiesForm
																		flagEsecutore={
																			flagEsecutore
																		}
																		overview={
																			document.overview
																		}
																		entities={
																			entities
																		}
																		onSubmit={
																			handleDocumentApprove
																		}
																		clientType={
																			document
																				.overview
																				.client_type
																		}
																		onMoreButtonClick={
																			!isReadOnly &&
																			label ===
																				"RID" &&
																			document
																				.overview
																				.provenienza
																				.value !==
																				"Piteco" &&
																			document
																				.overview
																				.status
																				.value !==
																				"inactive" &&
																			showHideButton &&
																			handleLoadHiddenFields
																		}
																		isReadOnly={
																			isReadOnly
																		}
																		id={id}
																	/>
																</FormProvider>
															</>
														)}

													{label === "RID" &&
													document.overview.status
														.value ===
														"d_generic" ? (
														<Alert severity="warning">
															Richiesta non
															gestibile attraverso
															la dashboard
														</Alert>
													) : (
														<>
															{document &&
																!isReadOnly &&
																isUploadFormEnabled() && (
																	<UploadForm
																		onUpload={
																			handleFileUpload
																		}
																		handleSetFile={(
																			fileToUpload
																		) =>
																			setFile(
																				fileToUpload
																			)
																		}
																		file={
																			file
																		}
																	/>
																)}
														</>
													)}
												</>
											)}
										</>
									)}
								</>
							)}

							{type === "dichiarazione" && (
								<>
									<CodiceFiscaleForm
										cf={document.email.codice_fiscale}
										onChange={(cf) => setCodiceFiscale(cf)}
										onSetPolizzaList={(data) => {
											setPolizzaList(data);
										}}
										isInitialLoadingEnabled={
											document.overview.status.value !==
												"completed" &&
											document.overview.status.value !==
												"deleted" &&
											document.overview.status.value !==
												"iter_erroneo"
										}
									/>
								</>
							)}
							<Box my={8}>
								{document.overview.status.value !==
									"completed" &&
									document.overview.status.value !==
										"deleted" &&
									document.overview.status.value !==
										"iter_erroneo" && (
										<Box>
											<Typography
												variant="h5"
												className={classes.footer}
											>
												Note
											</Typography>
											<TextField
												label="Nome autore"
												InputLabelProps={{
													shrink: true,
												}}
												value={username}
												placeholder="Autore"
												variant="outlined"
												style={{
													marginBottom: "1.3rem",
													marginTop: "1rem",
												}}
												disabled={true}
											/>
											<TextField
												id="document-notes"
												label="Appunti"
												multiline
												rows={4}
												fullWidth
												InputLabelProps={{
													shrink: true,
												}}
												value={documentNotes.text}
												onChange={(e) => {
													setDocumentNotes({
														...documentNotes,
														text: e.target.value,
														author: username,
														date: new Date(),
													});
												}}
												placeholder="Appunti sulla richiesta"
												variant="outlined"
												disabled={isReadOnly}
												style={{
													marginBottom: "0.7rem",
												}}
											/>
											<Box>
												<Button
													component="span"
													variant="contained"
													color="primary"
													size="large"
													style={{
														float: "right",
														marginBottom: "1.1rem",
													}}
													disabled={
														!documentNotes.text
													}
													onClick={() =>
														saveNoteData(
															id,
															type,
															documentNotes
														).then((response) => {
															getDocumentNotes(
																id,
																type
															);
															setDocumentNotes({
																author: "",
																text: "",
																date: null,
															});
														})
													}
												>
													Salva nota
												</Button>
											</Box>
										</Box>
									)}
								{retrievedNotes && retrievedNotes.length > 0 && (
									<Box>
										<Box>
											{modifyLastNoteMode ? (
												<TextField
													id="document-last-note"
													label="Appunti"
													multiline
													rows={4}
													fullWidth
													InputLabelProps={{
														shrink: true,
													}}
													value={lastNote?.text}
													onChange={(e) => {
														setLastNote({
															text: e.target
																.value,
															author: username,
															date: new Date(),
															id_nota:
																retrievedNotes[0]
																	?.id_nota,
														});
													}}
													variant="outlined"
													disabled={isReadOnly}
													style={{
														marginBottom: "0.7rem",
													}}
												/>
											) : (
												getNoteBox(
													retrievedNotes[0].author,
													retrievedNotes[0].text,
													retrievedNotes[0].date
												)
											)}
										</Box>
										<Box style={{ display: "flow-root" }}>
											<Button
												component="span"
												variant="contained"
												color="primary"
												size="large"
												style={{
													float: "right",
													marginBottom: "1.1rem",
													marginTop: "0.8rem",
												}}
												disabled={
													retrievedNotes[0].author !==
													username
												}
												onClick={() =>
													deleteNote(
														id,
														type,
														retrievedNotes[0].id_nota.toString()
													).then((response) => {
														getDocumentNotes(
															id,
															type
														);
														setDocumentNotes({
															author: "",
															text: "",
															date: null,
														});
													})
												}
											>
												Elimina
											</Button>
											<Button
												component="span"
												variant="contained"
												color="primary"
												size="large"
												style={{
													float: "right",
													marginBottom: "1.1rem",
													marginRight: "0.7rem",
													marginTop: "0.8rem",
												}}
												disabled={
													retrievedNotes[0].author !==
													username
												}
												onClick={() => {
													modifyLastNoteMode
														? modifyNote(
																id,
																type,
																lastNote
														  ).then((response) => {
																getDocumentNotes(
																	id,
																	type
																);
																setModifyLastNoteMode(
																	false
																);
																setDocumentNotes(
																	{
																		author: "",
																		text: "",
																		date: null,
																	}
																);
														  })
														: setModifyLastNoteMode(
																true
														  );
												}}
											>
												{modifyLastNoteMode
													? "Salva"
													: "Modifica"}
											</Button>
										</Box>
									</Box>
								)}
								{retrievedNotes && retrievedNotes.length > 1 && (
									<Box
										width="100%"
										style={{ marginTop: "1rem" }}
									>
										<Accordion>
											<AccordionSummary
												expandIcon={<ExpandMoreIcon />}
												aria-controls="panel1a-content"
												id="panel1a-header"
											>
												<Typography
													style={{
														fontWeight: "500",
													}}
												>
													Storico note
												</Typography>
											</AccordionSummary>
											{retrievedNotes?.map(
												(note, index) => {
													return (
														<AccordionDetails
															key={index}
															width="100%"
														>
															{getNoteBox(
																note.author,
																note.text,
																note.date
															)}
														</AccordionDetails>
													);
												}
											)}
										</Accordion>
									</Box>
								)}
							</Box>
							{isUnclassifiedDocument &&
								label === "DICHIARAZIONE" && (
									<Box style={{ marginBottom: "50px" }}>
										<Typography
											variant="h5"
											className={classes.footer}
										>
											Allegati
										</Typography>
										<Typography
											style={{
												color: "lightgray",
												marginTop: "5px",
												fontSize: "0.9rem",
											}}
										>
											I file caricati vengono salvati
											automaticamente
										</Typography>
										{attachmentFormLoading ? (
											<Grid
												container
												direction="row"
												justify="center"
											>
												<Box p={10}>
													<CircularProgress
														size={75}
													/>
												</Box>
											</Grid>
										) : (
											<UploadAttachmentForm
												showCheckUncheckButton={isOneAttachmentFileCheckable()}
												showUploadFileButton={
													document.overview.status
														.value !==
														"completed" &&
													document.overview.status
														.value !== "deleted" &&
													document.overview.status
														.value !==
														"iter_erroneo"
												}
												stringFileAccepted={
													"*Formati di allegato supportati dal documentale: PDF, DOCX"
												}
												checkUncheckButtonLabel={
													isAllAttachmentFileChecked()
														? "Deseleziona tutti i file"
														: "Seleziona tutti i file"
												}
												files={[
													...retrievedDocumentAttachmentFiles,
													...documentAttachmentFiles,
												]}
												style={{
													marginBottom: "0.7rem",
												}}
												idDocument={id}
												handleSelectAllFiles={
													handleSelectDeselectAllFile
												}
												handleRemoveFile={
													document.overview.status
														.value !==
														"completed" &&
													document.overview.status
														.value !== "deleted" &&
													document.overview.status
														.value !==
														"iter_erroneo" &&
													((attachment) =>
														handleRemoveFile(
															attachment
														))
												}
												handleSetFile={(attachment) => {
													if (
														retrievedDocumentAttachmentFiles.find(
															(file) =>
																file.filename ===
																attachment[0].name.replaceAll(
																	".",
																	"##"
																)
														)
													)
														return enqueueSnackbar(
															"File già esistente",
															{
																variant:
																	"error",
															}
														);
													const extension =
														attachment[0].name
															.toLowerCase()
															.split(".")
															.pop();
													if (!isAttachmentExtensionAccepted(extension))
														return enqueueSnackbar(
															"Formato file non accettato",
															{
																variant:
																	"error",
															}
														);
													getBase64(
														attachment[0],
														(result) => {
															const newDocumentAttachment =
																{
																	filename:
																		attachment[0]
																			.name,
																	editable:
																		isAttachmentExtensionAccepted(
																			extension
																		),
																	flag_upl:
																		isAttachmentExtensionAccepted(
																			extension
																		),
																	content:
																		result,
																};
															let newDocumentAttachmentFiles =
																documentAttachmentFiles.filter(
																	(doc) =>
																		doc.filename !==
																		attachment[0]
																			.name
																);
															newDocumentAttachmentFiles.push(
																newDocumentAttachment
															);
															setDocumentAttachmentFiles(
																newDocumentAttachmentFiles
															);
															handleDocumentAttachmentUpload(
																newDocumentAttachmentFiles,
																() => {
																	return enqueueSnackbar(
																		"Caricamento avvenuto con successo",
																		{
																			variant:
																				"success",
																		}
																	);
																}
															);
														}
													);
												}}
												type={type}
												handleFilesChecked={(
													file,
													isChecked
												) => {
													let newDocumentAttachmentFiles;
													if (
														file.content?.includes(
															"data"
														)
													)
														newDocumentAttachmentFiles =
															[
																...documentAttachmentFiles,
															];
													else
														newDocumentAttachmentFiles =
															[
																...retrievedDocumentAttachmentFiles,
															];
													newDocumentAttachmentFiles.forEach(
														(newFile) => {
															if (
																newFile.filename ===
																file.filename
															) {
																newFile.flag_upl =
																	isChecked;
															}
														}
													);
													if (
														file.content?.includes(
															"data"
														)
													)
														setDocumentAttachmentFiles(
															newDocumentAttachmentFiles
														);
													else
														setRetrievedDocumentAttachmentFiles(
															newDocumentAttachmentFiles
														);
												}}
											/>
										)}
									</Box>
								)}

							{documentDataIsaac &&
							<Box>
								<Typography
									variant="h5"
									className={classes.footer}
								>
									Dati isaac
								</Typography>
								<Grid container spacing={3}>
									{Object.keys(documentDataIsaac.data.entita_estratte).map((entity) => {
										if (Object.keys(documentDataIsaac.data.entita_estratte[entity]).length !== 0)
										return <Grid item><TextField
											label={entity}
											InputLabelProps={{
												shrink: true,
											}}
											value={documentDataIsaac.data.entita_estratte[entity]}
											style={{
												marginBottom: "1.3rem",
												marginTop: "1rem",
											}}
											variant="standard"
											disabled={true}
										/></Grid>
									})}
								</Grid>
								<Typography
									variant="h6"
								>
									Classificazione ticket Isaac
								</Typography>
								<Grid container spacing={3}>
									{Object.keys(documentDataIsaac.data.classificazione).map((entity) => {
										return <Grid item><TextField
											label={entity}
											InputLabelProps={{
												shrink: true,
											}}
											value={documentDataIsaac.data.classificazione[entity]}
											style={{
												marginBottom: "1.3rem",
												marginTop: "1rem",
											}}
											variant="standard"
											disabled={true}
										/></Grid>
									})}
								</Grid>
								{documentDataIsaac.data.note_isaac && documentDataIsaac.data.note_isaac.length > 0 &&
									getNoteBox(
										documentDataIsaac.data.note_isaac[0].operatore,
										documentDataIsaac.data.note_isaac[0].testo,
										documentDataIsaac.data.note_isaac[0].data
									)
								}
								{documentDataIsaac.data.note_isaac && documentDataIsaac.data.note_isaac.length > 1 && (
									<Box
										width="100%"
										style={{ marginTop: "1rem" }}
									>
										<Accordion>
											<AccordionSummary
												expandIcon={<ExpandMoreIcon />}
												aria-controls="panel1a-content"
												id="panel1a-header"
											>
												<Typography
													style={{
														fontWeight: "500",
													}}
												>
													Storico note
												</Typography>
											</AccordionSummary>
											{documentDataIsaac.data.note_isaac?.map(
												(note, index) => {
													return (
														<AccordionDetails
															key={index}
															width="100%"
														>
															{getNoteBox(
																note.operatore,
																note.testo,
																note.data
															)}
														</AccordionDetails>
													);
												}
											)}
										</Accordion>
									</Box>
								)}
							</Box>}

							{document.overview.provenienza.value !==
								"Piteco" && (
								<Email
									email={document.email}
									id={id}
									type={type}
									attachmentData={emailAttachmentFiles}
									handleFilesChecked={(
										attachment,
										isChecked
									) => {
										let newEmailAttachmentFiles = [
											...emailAttachmentFiles,
										];
										newEmailAttachmentFiles.forEach(
											(newFile) => {
												if (
													newFile.content ===
													attachment.content
												) {
													newFile.flag_upl =
														isChecked;
												}
											}
										);
										setEmailAttachmentFiles(
											newEmailAttachmentFiles
										);
									}}
								/>
							)}
						</>
					)}
				</Paper>
			</Grid>
		</Grid>
	);
}

Document.propTypes = {
	isReadOnly: PropTypes.bool,
};
