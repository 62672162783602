import React from "react";
import { CssBaseline, ThemeProvider } from "@material-ui/core";
import { SnackbarProvider } from "notistack";
import AzureAD from "react-aad-msal";
import {
	Route,
	Switch,
	BrowserRouter as Router,
	Redirect,
} from "react-router-dom";
import { theme } from "assets/themes";
import { authProvider } from "services/auth";
import { ConfirmerProvider } from "shared/ConfirmerProvider";
import { Layout } from "views/Layout";
import { Login } from "views/Login";
import { Validate } from "views/Validate";
import { ValidateDichiarazione } from "views/ValidateDichiarazione";

function App() {
	return (
		<Router>
			<ThemeProvider theme={theme}>
				<ConfirmerProvider>
					<SnackbarProvider>
						<Switch>
							<Route path="/validate_rid" component={Validate} />
							<Route
								path="/validate_dichiarazione"
								component={ValidateDichiarazione}
							/>
							<Route path="/dashboard">
								<AzureAD provider={authProvider}>
									{({
										login,
										logout,
										authenticationState,
									}) => {
										switch (authenticationState) {
											case "Authenticated":
												return (
													<Layout onLogout={logout} />
												);
											case "Unauthenticated":
												return (
													<Login onLogin={login} />
												);
										}
									}}
								</AzureAD>
							</Route>
							<Route path="/">
								<Redirect to="/dashboard" />
							</Route>
						</Switch>

						<CssBaseline />
					</SnackbarProvider>
				</ConfirmerProvider>
			</ThemeProvider>
		</Router>
	);
}

export default App;
