import urljoin from "url-join";
import { axiosInstance, baseUrl } from "services/api";

const prefix = "/dashboard/docs";

function getDocumentData(id, type) {
	return axiosInstance.get(urljoin(`/${type}/services`, prefix, id));
}

function getDocumentDataIsaac(id, type) {
	return axiosInstance.get(urljoin(`/${type}/services`, prefix, "isaac", id));
}

function getDocumentEntities(id, type, toSend) {
	return axiosInstance.post(urljoin(`/${type}/services`, prefix, id), toSend);
}

function closeDocument(id, type) {
	return axiosInstance.delete(urljoin(`/${type}/services`, prefix, id));
}

function updateDocument(id, type, toSend) {
	return axiosInstance.put(
		urljoin(`/${type}/services`, prefix, id, "update"),
		toSend
	);
}

function getAttachmentData(id, type) {
	return axiosInstance.get(
		urljoin(`/${type}/services`, prefix, id, "allegati")
	);
}

function uploadAttachmentDocumentData(id, type, toSend) {
	return axiosInstance.post(
		urljoin(`/${type}/services`, prefix, id, "allegati"),
		toSend
	);
}

function deleteAttachmentDocumentData(id, type, toSend) {
	return axiosInstance.delete(
		urljoin(`/${type}/services`, prefix, id, "allegati"),
		{ data: toSend }
	);
}

function getDocumentAttachmentLinkDownload(id, type, contentId, fileName) {
	return axiosInstance.get(urljoin(baseUrl, `/${type}/services`, prefix, id, contentId), {responseType: 'blob'})
		.then((response) => {
			const url = window.URL.createObjectURL(new Blob([response.data]));
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute('download', fileName.replaceAll('##', '.')); //or any other extension
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
		});
}

function reElaborateDocument(id, type, toSend) {
	return axiosInstance.post(
		urljoin(`/${type}/services`, prefix, id, "manual"),
		toSend
	);
}

function approveDocument(id, type, toSend) {
	return axiosInstance.put(urljoin(`/${type}/services`, prefix, id), toSend);
}

function getHiddenFields(id, type, toSend) {
	return axiosInstance.post(
		urljoin(`/${type}/services`, prefix, id, "hidden"),
		toSend
	);
}

function uploadFileData(id, agenzia, formData, type) {
	return axiosInstance.post(
		urljoin(`/${type}/services`, prefix, id, "file"),
		formData,
		{
			params: {
				agenzia,
			},
			headers: { "Content-Type": "multipart/form-data" },
		}
	);
}

function downloadAttachment(docId, attachmentId, type) {
	return axiosInstance.get(
		urljoin(`/${type}/services`, prefix, docId, attachmentId),
		{
			responseType: "blob",
		}
	);
}

function getSublabels(label, type) {
	return axiosInstance.get(
		urljoin(`/${type}/services`, prefix, `${label}/sublabels`)
	);
}

function getCfFields(value) {
	return axiosInstance.get(
		urljoin("dichiarazione", "services", prefix, "polizze", value)
	);
}

function getNoteData(id, type) {
	return axiosInstance.get(
		urljoin(`/${type}/services/dashboard/docs/`, id, "note")
	);
}

function closePraticaIsaac(id, type) {
	return axiosInstance.post(urljoin(`/${type}/services`, prefix, id, "close"));
}

function changeUserPratica(id, type, toSend) {
	return axiosInstance.post(urljoin(`/${type}/services`, prefix, id, "passto"), toSend);
}


function checkEntityErrors(value) {
	return axiosInstance.get(
		urljoin("/dichiarazione/services", "certs", value, "valid")
	);
}
function saveNoteData(id, type, toSend) {
	return axiosInstance.post(
		urljoin(`/${type}/services/dashboard/docs/`, id, "note"),
		toSend
	);
}

function deleteNote(id, type, noteId) {
	return axiosInstance.delete(
		urljoin(`/${type}/services/dashboard/docs/`, id, "note", noteId)
	);
}

function modifyNote(id, type, toSend) {
	return axiosInstance.patch(
		urljoin(`/${type}/services/dashboard/docs/`, id, "note"),
		toSend
	);
}

export {
	getDocumentData,
	getDocumentEntities,
	closeDocument,
	updateDocument,
	reElaborateDocument,
	approveDocument,
	getHiddenFields,
	uploadFileData,
	downloadAttachment,
	getSublabels,
	getCfFields,
	getNoteData,
	saveNoteData,
	getAttachmentData,
	uploadAttachmentDocumentData,
	getDocumentAttachmentLinkDownload,
	checkEntityErrors,
	deleteAttachmentDocumentData,
	modifyNote,
	deleteNote,
	getDocumentDataIsaac,
	closePraticaIsaac,
	changeUserPratica
};
