import React from "react";
import { Chip } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
	chip: {
		margin: theme.spacing(0.5),
	},
}));

export function Filter({ label }) {
	const classes = useStyles();

	return (
		<Chip
			label={label}
			color="primary"
			variant="outlined"
			className={classes.chip}
		/>
	);
}

Filter.propTypes = {
	label: PropTypes.string,
	onDelete: PropTypes.func,
};
