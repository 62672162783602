import React, { useEffect, useState } from "react";
import {
	Box,
	Checkbox,
	Container,
	FormControl,
	FormControlLabel,
	Grid,
	Paper,
	Select,
	Typography,
	InputLabel,
	MenuItem,
	FormHelperText,
	Button,
	Link,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogContentText,
	DialogActions,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Add, Remove } from "@material-ui/icons";
import Alert from "@material-ui/lab/Alert";
import { useSnackbar } from "notistack";
import { useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";
import logo from "assets/images/logo.png";
import { getHiddenFields } from "components/Document/api";
import { axiosInstance } from "services/api";
import { ClientTypeRadio } from "shared/ClientTypeRadio";
import { Field } from "shared/Field";
import { Footer } from "shared/Footer";
import { Loader } from "shared/Loader";
import { disableEntities, getErrorMessage, matchPattern } from "utils";

const useStyles = makeStyles((theme) => ({
	header: {
		backgroundColor: "white",
		minHeight: "100px",
		display: "flex",
		alignItems: "center",
		justifyContent: "flex-start",
	},
	logo: {
		[theme.breakpoints.down("sm")]: {
			textAlign: "center",
		},
	},
	content: {
		backgroundImage: "url('../assets/images/landing-page-background.png')",
		backgroundPosition: "top center",
		backgroundRepeat: "no-repeat",
		backgroundSize: "100%",
		[theme.breakpoints.down("sm")]: {
			backgroundSize: "auto 400px",
		},
	},
	paper: {
		height: "100%",
		display: "flex",
		textAlign: "center",
		alignItems: "center",
		transition: "all 0.3s",
		justifyContent: "center",
		padding: theme.spacing(2),
		cursor: "pointer",
		color: theme.palette.text.secondary,
		"&:hover": {
			color: "black",
			background: "transparent",
		},
	},
	title: {
		color: "white",
		textShadow: "0 0 7px #000",
	},
	errorMessage: {
		color: theme.palette.error.main,
		textAlign: "center",
	},
	successMessage: {
		textAlign: "center",
	},
	countdown: {
		color: "#317ED9",
	},
	formControl: {
		marginBottom: theme.spacing(2),
		width: "100%",
	},
	errorAlertIcon: {
		color: "#D16405",
		fontSize: 16,
	},
	privacyCheckboxLabel: {
		[theme.breakpoints.down("sm")]: {
			fontSize: "0.75rem",
		},
	},
	submitButton: {
		minWidth: "240px",
		maxWidth: "240px",
		borderRadius: 0,
		margin: "2rem auto",
		transform: "translateX(-50%)",
		position: "absolute",
		left: "50%",
		bottom: "-3.325rem",
	},
	footerLinks: {
		fontFamily: "'Titillium Web', sans-serif",
		px: 15,
		[theme.breakpoints.down("sm")]: {
			flexDirection: "column",
			px: 2,
		},
	},
	select: {
		"& ul": {
			padding: 0,
		},
		"& li": {
			padding: "0.75rem",
			fontSize: 14,
		},
	},
}));

export function Validate() {
	const classes = useStyles();
	const { enqueueSnackbar } = useSnackbar();
	const { register, errors, reset, handleSubmit, getValues } = useForm({
		mode: "onBlur",
	});

	const [document, setDocument] = useState(null);
	const [loading, setLoading] = useState(true);
	const [privacyModalOpen, setPrivacyModalOpen] = useState(false);
	const [error, setError] = useState(null);
	const [esecutoreFields, setEsecutoreFields] = useState(null);
	const [submitted, setSubmitted] = useState(false);
	const [showHideButton, setShowHideButton] = useState(true);

	const cert = new URLSearchParams(useLocation().search).get("cert");

	useEffect(() => {
		axiosInstance
			.post(
				`/rid/services/certs/fields?certificato=${cert}`,
				{},
				{
					headers: {
						skipBearer: false,
					},
				}
			)
			.then(({ data }) => {
				setDocument({
					...data,
					entities: disableEntities(data.entities, data.client_type),
				});

				setLoading(false);
				setError(null);
			})
			.catch((error) => {
				const errorMessage =
					error.response?.data.description ||
					"Impossibile ricavare le informazioni";

				setError(errorMessage);
				setLoading(false);
			});
	}, [cert]);

	function handleSubLabelChange(event) {
		const newSubLabel = event.target.value;
		setLoading(true);

		axiosInstance
			.post(
				`/rid/services/certs/fields?certificato=${cert}`,
				{
					compagnia: document.compagnia,
					label: document.label,
					sub_label: newSubLabel,
				},
				{
					headers: {
						skipBearer: false,
					},
				}
			)
			.then(({ data }) => {
				setDocument({
					...data,
					entities: disableEntities(
						data.entities,
						document.client_type
					),
				});
				setShowHideButton(true);
				setLoading(false);
			})
			.catch((error) => {
				setShowHideButton(true);
				setLoading(false);
				enqueueSnackbar(getErrorMessage(error), {
					variant: "error",
				});
			});
	}

	function handleCompagniaChange(event) {
		const newCompagnia = event.target.value;
		setLoading(true);

		axiosInstance
			.post(
				`/rid/services/certs/fields?certificato=${cert}`,
				{
					compagnia: newCompagnia,
					label: document.label,
					sub_label: document.sub_label,
				},
				{
					headers: {
						skipBearer: false,
					},
				}
			)
			.then(({ data }) => {
				setDocument({
					...data,
					entities: disableEntities(
						data.entities,
						document.client_type
					),
				});
				setShowHideButton(true);
				setLoading(false);
			})
			.catch((error) => {
				setShowHideButton(true);
				setLoading(false);
				enqueueSnackbar(getErrorMessage(error), {
					variant: "error",
				});
			});
	}

	function handleLoadHiddenFields() {
		if (!showHideButton) {
			const names = esecutoreFields.map((field) => field.name);

			// FILTRO LE ENTITA' ESECUTORE
			const newEntities = document.entities.filter(
				(entity) => !names.includes(entity.name)
			);

			setDocument({ ...document, entities: newEntities });
			setShowHideButton(true);
		} else {
			setLoading(true);
			const updatedForm = getValues();
			let uptadedEntities = [...document.entities];

			Object.entries(updatedForm).map(([key, value]) => {
				const index = uptadedEntities.findIndex(
					(field) => field.name === key
				);

				if (index !== -1) {
					uptadedEntities[index].value = value;
				}
			});

			getHiddenFields(cert, "rid", {
				label: document.label,
				sub_label: document.sub_label,
				compagnia: document.compagnia,
			})
				.then(({ data }) => {
					setEsecutoreFields(data);
					const newEntities = [...uptadedEntities, ...data];

					setDocument({
						...document,
						entities: newEntities,
					});

					setShowHideButton(false);
					setLoading(false);
				})
				.catch((error) => {
					setLoading(false);
					enqueueSnackbar(getErrorMessage(error), {
						variant: "error",
					});
				});
		}
	}

	function handleClientTypeChange(value) {
		setDocument({
			...document,
			entities: disableEntities(document.entities, value),
			client_type: value,
		});

		// TODO: FARE IN MODO DI NON PERDERE I VALORI MODIFICATI NON GIURIDICI AL CHANGE DEL TYPE
		let entitiesValue = {};

		if (value && value === "giuridica") {
			entitiesValue = getValues();

			document.entities.forEach((entity) => {
				if (entity.giuridico && entity.value !== undefined) {
					entitiesValue[entity.name] = "";
				} else {
					entitiesValue[entity.name] = entity.value;
				}
			});
		} else {
			document.entities.forEach((entity) => {
				entitiesValue[entity.name] = entity.value;
			});
		}

		reset(entitiesValue);

		// if (value === "giuridica") {
		// 	let entitiesValue = getValues();

		// 	const entitiesGiuridiche = document.entities
		// 		.filter((entity) => entity.giuridico)
		// 		.map((entity) => entity.name);

		// 	entitiesGiuridiche.forEach((entity) => {
		// 		if (entitiesValue[entity] !== undefined) {
		// 			entitiesValue[entity] = "";
		// 		}
		// 	});

		// 	reset(entitiesValue);
		// }
	}

	function onSubmit(data) {
		let newData = { ...data };

		for (const entity in newData) {
			if (
				newData[entity] === null ||
				newData[entity] === "" ||
				entity === "checkbox"
			) {
				delete newData[entity];
			}
		}

		const toSend = {
			label: document.label,
			client_type: document.client_type,
			sub_label: document.sub_label,
			sender: document.sender,
			compagnia: document.compagnia,
			entities: newData,
		};

		setLoading(true);
		axiosInstance
			.post(`/rid/services/certs/save?certificato=${cert}`, toSend, {
				headers: {
					skipBearer: false,
				},
			})
			.then(() => {
				setLoading(false);
				setSubmitted(true);
			})
			.catch((error) => {
				setLoading(false);
				reset(data);

				enqueueSnackbar(getErrorMessage(error), {
					variant: "error",
				});
			});
	}

	return (
		<Box id="landing_page">
			<Box id="header" className={classes.header}>
				<Container id="container" fixed={true} maxWidth="md">
					<Grid item xs={12} className={classes.logo}>
						<img
							src={logo}
							alt="Reale Mutua logo"
							width="170"
							height="42"
						/>
					</Grid>
				</Container>
			</Box>
			<Box id="content" className={classes.content}>
				<Container id="form_container" fixed>
					<Grid container direction="row" justify="center">
						<Grid item xs={12} sm={12} md={10} lg={7}>
							<Box my={4}>
								<Typography
									variant="h4"
									align="center"
									className={classes.title}
								>
									Richiesta integrazione dati
								</Typography>
							</Box>
							<Box my={4} pb={8} position="relative">
								<Loader isLoading={loading} spinnerSize={75}>
									<Paper
										square
										elevation={5}
										style={{
											minHeight: "50vh",
											padding: "2rem 2rem 4rem",
										}}
									>
										{error ? (
											<Typography
												variant="h5"
												paragraph={true}
												className={classes.errorMessage}
											>
												{error}
											</Typography>
										) : (
											<>
												{submitted ? (
													<Typography
														variant="h5"
														paragraph={true}
														className={
															classes.successMessage
														}
													>
														Abbiamo ricevuto la tua
														richiesta. Grazie
													</Typography>
												) : (
													!loading && (
														<>
															<Box mb={4}>
																<Typography
																	variant="subtitle1"
																	paragraph={
																		true
																	}
																	className={
																		classes.countdown
																	}
																>
																	Modulo di
																	inserimento
																	dati
																	personali.
																</Typography>
															</Box>

															<Grid
																container
																spacing={2}
															>
																<Grid
																	item
																	xs={12}
																	sm={6}
																>
																	<FormControl
																		className={
																			classes.formControl
																		}
																	>
																		<InputLabel>
																			Tipologia
																		</InputLabel>
																		<Select
																			value={
																				document.sub_label
																			}
																			onChange={
																				handleSubLabelChange
																			}
																			MenuProps={{
																				classes:
																					{
																						paper: classes.select,
																					},
																			}}
																		>
																			<MenuItem value="ADESIONE">
																				Adesione
																				RID
																			</MenuItem>
																			<MenuItem value="REVOCA">
																				Revoca
																				RID
																			</MenuItem>
																			<MenuItem value="VARIAZIONE">
																				Variazione
																				RID
																			</MenuItem>
																		</Select>
																	</FormControl>
																</Grid>
																<Grid
																	item
																	xs={12}
																	sm={6}
																>
																	<FormControl
																		className={
																			classes.formControl
																		}
																	>
																		<InputLabel
																			shrink
																		>
																			Compagnia
																		</InputLabel>
																		<Select
																			value={
																				document.compagnia
																			}
																			onChange={
																				handleCompagniaChange
																			}
																			MenuProps={{
																				classes:
																					{
																						paper: classes.select,
																					},
																			}}
																		>
																			<MenuItem value="RMA">
																				Reale
																				Mutua
																				assicurazioni
																			</MenuItem>
																			<MenuItem value="ITA">
																				Italiana
																				assicurazioni
																			</MenuItem>
																		</Select>
																	</FormControl>
																</Grid>
															</Grid>

															{document.compagnia ? (
																<>
																	<ClientTypeRadio
																		value={
																			document.client_type
																		}
																		onChange={
																			handleClientTypeChange
																		}
																	/>

																	<form
																		onSubmit={handleSubmit(
																			onSubmit
																		)}
																	>
																		<Grid
																			container
																			spacing={
																				2
																			}
																		>
																			{document.entities.map(
																				(
																					entity
																				) => (
																					<Grid
																						key={
																							entity.name
																						}
																						item
																						xs={
																							12
																						}
																						sm={
																							6
																						}
																					>
																						<Field
																							entity={
																								entity
																							}
																							error={
																								errors[
																									entity
																										.name
																								]
																							}
																							entityRef={register(
																								{
																									pattern:
																										matchPattern(
																											entity.pattern
																										),
																									required:
																										entity.mandatory,
																								}
																							)}
																						/>
																					</Grid>
																				)
																			)}
																			<Grid
																				item
																				xs={
																					12
																				}
																			>
																				{showHideButton ? (
																					<Button
																						color="primary"
																						startIcon={
																							<Add />
																						}
																						onClick={
																							handleLoadHiddenFields
																						}
																					>
																						Carica
																						entità
																						esecutore
																					</Button>
																				) : (
																					<Button
																						color="primary"
																						startIcon={
																							<Remove />
																						}
																						onClick={
																							handleLoadHiddenFields
																						}
																					>
																						Nascondi
																						entità
																						esecutore
																					</Button>
																				)}
																			</Grid>
																			<Grid
																				item
																				xs={
																					12
																				}
																			>
																				<FormControl
																					required
																					error={
																						!!errors[
																							"checkbox"
																						]
																					}
																				>
																					<FormControlLabel
																						className={
																							classes.privacyCheckboxLabel
																						}
																						name="checkbox"
																						inputRef={register(
																							{
																								required: true,
																							}
																						)}
																						control={
																							<Checkbox color="primary" />
																						}
																						label={
																							<span>
																								Dichiaro
																								di
																								aver
																								letto{" "}
																								<Link
																									href="#"
																									onClick={(
																										e
																									) => {
																										e.preventDefault();
																										setPrivacyModalOpen(
																											true
																										);
																									}}
																								>
																									l&apos;informativa
																									sulla
																									privacy
																								</Link>
																							</span>
																						}
																					/>
																					{!!errors[
																						"checkbox"
																					] && (
																						<FormHelperText>
																							Richiesto
																						</FormHelperText>
																					)}
																				</FormControl>
																			</Grid>
																			<Button
																				color="secondary"
																				variant="contained"
																				fullWidth
																				type="submit"
																				size="large"
																				className={
																					classes.submitButton
																				}
																			>
																				Invia
																			</Button>
																		</Grid>
																	</form>
																</>
															) : (
																<Alert
																	severity="info"
																	style={{
																		marginTop:
																			"1rem",
																	}}
																>
																	Seleziona
																	una
																	compagnia
																	prima di
																	procedere
																</Alert>
															)}
														</>
													)
												)}
											</>
										)}
									</Paper>
								</Loader>
							</Box>
						</Grid>
					</Grid>
				</Container>
			</Box>

			<Dialog
				open={privacyModalOpen}
				onClose={() => setPrivacyModalOpen(false)}
			>
				<DialogTitle>Informativa sulla privacy</DialogTitle>
				<DialogContent>
					<DialogContentText>
						I dati personali richiesti saranno trattati per le
						finalità e secondo le modalità indicate nell’informativa
						privacy per contraenti e assicurati redatta ai sensi
						degli artt. 13 e 14 del Regolamento (UE) 2016/679
						(Regolamento generale sulla protezione dei dati) che le
						è stata consegnata in virtù del rapporto contrattuale in
						essere con la società; potrà consultare l’informativa
						nella sezione “cookie e privacy” sul sito della
						Compagnia.
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button
						onClick={() => setPrivacyModalOpen(false)}
						color="primary"
						autoFocus
					>
						Chiudi
					</Button>
				</DialogActions>
			</Dialog>

			<Footer />
		</Box>
	);
}
