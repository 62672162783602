import React from "react";
import { CircularProgress, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";

const useStyles = makeStyles(() => ({
	overlay: {
		display: "flex",
		//flexDirection: "column",
		justifyContent: "center",
		alignItems: "center",
		backgroundColor: "rgba(255, 255, 255, 0.75)",
		position: "absolute",
		zIndex: 999,
		top: 0,
		left: 0,
		right: 0,
		bottom: 0,
	},
}));

export function Loader({
	isLoading,
	loadingLabel,
	spinnerSize = 30,
	children,
}) {
	const classes = useStyles();

	return (
		<div style={{ position: "relative" }}>
			{isLoading && (
				<div className={classes.overlay}>
					<div
						style={{
							display: "flex",
							flexDirection: "column",
							justifyContent: "center",
							alignItems: "center",
						}}
					>
						<CircularProgress
							size={spinnerSize}
							thickness={4}
							variant="indeterminate"
						/>
						{loadingLabel && (
							<Typography
								variant="subtitle2"
								style={{
									color: "#AAA",
									fontWeight: "bold",
									fontSize: "0.875rem",
									marginTop: "0.5rem",
								}}
							>
								{loadingLabel}
							</Typography>
						)}
					</div>
				</div>
			)}
			{children}
		</div>
	);
}

Loader.propTypes = {
	isLoading: PropTypes.bool,
	loadingLabel: PropTypes.string,
	children: PropTypes.element,
	spinnerSize: PropTypes.number,
};
