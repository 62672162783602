import React from "react";
import { Grid, Paper, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { Loader } from "./Loader";

const useStyles = makeStyles((theme) => ({
	paper: {
		width: "100%",
		minHeight: "40px",
		display: "flex",
		alignItems: "center",
		justifyContent: "space-between",
		padding: theme.spacing(0, 2),
		overflow: "hidden",
		backgroundColor: "#eee",
		cursor: "pointer",
	},
	statLabel: {
		maxWidth: "75%",
		fontSize: "87.5%",
		paddingRight: "4px",
	},
	statValue: {
		fontWeight: "bold",
		color: theme.palette.secondary.main,
	},
	stat: {
		margin: "0.25rem",
	},
}));

export function Stat({ label, value, isLoading, ...rest }) {
	const classes = useStyles();

	return (
		<Grid item xs={6} sm={3} md={3} lg={2} xl={2}>
			<Loader isLoading={isLoading}>
				<Paper
					className={classes.paper}
					title={`${label}: ${value}`}
					elevation={0}
					{...rest}
				>
					<Typography
						variant="subtitle2"
						noWrap
						className={classes.statLabel}
						color="primary"
					>
						{label}
					</Typography>
					<Typography
						variant="subtitle2"
						className={classes.statValue}
					>
						{value}
					</Typography>
				</Paper>
			</Loader>
		</Grid>
	);
}

Stat.propTypes = {
	label: PropTypes.string,
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	isLoading: PropTypes.bool,
};
