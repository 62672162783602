import { useEffect, useState } from "react";
import { decodeJwt } from "services/api";
import { authProvider } from "services/auth";

const mappingRole = {
	admin: [
		"0bcf050a-d563-45dd-885f-bae23d77ecf3",
		"7d02ce11-d983-4c5a-8c3f-d8d2b2980ec7",
		"d908e96c-0791-4cf1-bbb5-1dcd03bae7db",
		"069acd6d-bcfb-45e9-90ca-b935229fb887",
		"208bef27-9b86-4737-8fe7-cd1aedab19cc",
		"d05e1287-427c-43ac-8f3e-e05b681b7605",
		"dfec477c-2378-4750-9327-2dd71adb0e81",
		"09d0a77d-a3d4-4d6c-b720-a78b8748af22",
		"cfa2ddb4-d52f-4d55-8f0b-b78cce43865b",
		"2d675d92-32fe-412a-8678-ae1b3a6cace5",
	],
	refVita: [
		"1992cc7d-1127-4365-9ec3-6408e3afdabc",
		"41f1e9ff-33d7-4034-895e-7302c473e4f2",
		"e1fd275e-6937-40e9-b23c-d5f44e73ebbe",
		"e96fc505-a380-4e5e-b48f-b1c6c2ccb500",
		"90bcdcff-e6ff-4413-bff0-5555a81113d0",
		"6dd61ef5-7427-4ad9-b98a-b36cb3baf901",
		"683707e9-a0c5-4388-9510-22f6be449dcd",
		"e815d042-c690-404f-82d2-c3e80be7576f",
		"47197fe1-8c21-4c54-bf70-70b1f30466f2",
		"998f49fd-8135-4a5a-869c-015e95d5c9f9",
	],
	oper1: [
		"a22f965e-aac5-4910-aafc-a27e11dc3487",
		"aba6d314-f135-49d5-8696-67a341b06f51",
		"1044fb28-e124-451b-a1f2-d38107791ae8",
		"182f590e-f7ad-4a36-80ff-52908525d1bd",
		"2e5b1994-b6b1-4619-a50b-e8dca490b5a1",
		"52ce37c6-7442-46a4-ba20-914a49c2a7a8",
		"d3f16971-6650-4a43-80a1-ebe2523ece7a",
		"38a1569e-db60-47ff-b156-d6a0ea830ab2",
		"36534c5e-8011-4c35-9f02-2c314f948cf3",
		"ad7c3cfd-2356-4897-a3b5-225c775cb7c9",
	],
	oper2: [],
};

export function useRole() {
	const [token, setToken] = useState(null);
	const [groups, setGroups] = useState(null);
	const [roles, setRoles] = useState({});

	useEffect(() => {
		authProvider.getIdToken().then((resp) => {
			setToken(resp.idToken.rawIdToken);
		});
	}, []);

	useEffect(() => {
		if (token) {
			const d = decodeJwt(token);
			setGroups(d.groups);
		}
	}, [token]);

	useEffect(() => {
		let isConfig = false;
		let isUffVita = false;
		let isITUser = false;
		let isOper2 = false;

		{
			if (groups) {
				groups.map((group) => {
					if (mappingRole.admin.includes(group)) {
						isConfig = true;
					} else if (mappingRole.refVita.includes(group)) {
						isUffVita = true;
					} else if (mappingRole.oper1.includes(group)) {
						isITUser = true;
					} else if (mappingRole.oper2.includes(group)) {
						isOper2 = true;
					}
				});

				setRoles({ isConfig, isUffVita, isITUser, isOper2 });
			}
		}
	}, [groups]);

	return roles;
}
