import React from "react";
import {
	Box,
	Checkbox,
	makeStyles,
	Grid,
	Typography,
	Button, Link,
} from "@material-ui/core";
import CancelIcon from "@material-ui/icons/CancelRounded";
import { FileUpload } from "../../shared/FileUpload";
import { getDocumentAttachmentLinkDownload } from "./api";

const useStyles = makeStyles(() => ({
	fileGrid: {
		overflow: "hidden",
		whiteSpace: "nowrap",
		textOverflow: "ellipsis",
	},
	allFilesGrid: {
		border: "1px solid lightgray",
		borderRadius: "5px",
		padding: "0.5rem",
	},
}));

export function UploadAttachmentForm({
	handleSetFile,
	idDocument,
	type,
	files,
	handleFilesChecked,
	showCheckUncheckButton = true,
	handleSelectAllFiles,
	stringFileAccepted = "Seleziona tutti i file",
	style,
	checkUncheckButtonLabel,
	buttonMarginRight = {
		marginRight: 0,
	},
	showUploadFileButton = true,
	buttonStyle = { width: "238px" },
	buttonSize = "large",
	handleRemoveFile,
}) {
	const classes = useStyles();
	let fileBox = [];
	if (files)
		for (let i = 0; i < files.length; i++) {
			fileBox.push(
				<Grid item xs={4} className={classes.fileGrid} key={i}>
					<Box style={{ display: "flex", alignItems: "center" }}>
						{handleRemoveFile &&
							type === "dichiarazione" &&
							(files[i].editable || !files[i].flag_upl) && (
								<CancelIcon
									color={"error"}
									style={{ cursor: "pointer" }}
									onClick={() => handleRemoveFile(files[i])}
								/>
							)}
						{handleFilesChecked && type === "dichiarazione" && (
							<Checkbox
								style={{ paddingLeft: "2px" }}
								checked={files[i].flag_upl}
								disabled={!files[i].editable}
								onChange={(e) =>
									handleFilesChecked(
										files[i],
										e.target.checked
									)
								}
							/>
						)}
						{files[i].content?.includes("data") ? (
							<Typography noWrap>{files[i].filename}</Typography>
						) : (
							<Link
								style={{ cursor: "pointer" }}
								onClick={() => getDocumentAttachmentLinkDownload(
									idDocument,
									type,
									files[i].content ? files[i].content : "",
									files[i].filename
								)}
							>
								<Typography noWrap>
									{files[i].filename}
								</Typography>
							</Link>
						)}
					</Box>
				</Grid>
			);
		}
	return (
		<form id="uploadAttachmentForm" style={{ ...style, marginTop: "1rem" }}>
			<Box style={{ display: "flex", width: "100%" }}>
				<Box
					style={{
						...buttonMarginRight,
						alignItems: "center",
					}}
				>
					{showUploadFileButton && (
						<FileUpload
							onUpload={handleSetFile}
							buttonStyle={buttonStyle}
							multiple={true}
							label={"Aggiungi file"}
							buttonSize={buttonSize}
						/>
					)}
					{handleFilesChecked &&
						showUploadFileButton &&
						showCheckUncheckButton &&
						files &&
						files.length > 0 && (
							<Box style={buttonStyle}>
								<Button
									component="span"
									variant="contained"
									color="secondary"
									size="large"
									onClick={handleSelectAllFiles}
									style={buttonStyle}
								>
									{checkUncheckButtonLabel}
								</Button>
							</Box>
						)}
					<Box
						style={{
							color: "lightgray",
							marginTop: "5px",
							...buttonStyle,
						}}
					>
						<Typography style={{ fontSize: "0.8rem" }}>
							{stringFileAccepted}
						</Typography>
					</Box>
				</Box>
				{files && (
					<Grid
						container
						rowSpacing={1}
						columnSpacing={{ xs: 1, sm: 2, md: 4 }}
						spacing={{ xs: 1, md: 3 }}
						columns={{ xs: 2, sm: 4, md: 12 }}
						className={classes.allFilesGrid}
					>
						{fileBox}
					</Grid>
				)}
			</Box>
		</form>
	);
}
