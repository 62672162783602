import React, { useState, useEffect } from "react";
import {
	Box,
	Container,
	Grid,
	Paper,
	Typography,
	Button,
	Link,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogContentText,
	DialogActions,
	FormControl,
	FormHelperText,
	Radio,
	FormLabel,
	FormControlLabel,
	RadioGroup,
} from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import { makeStyles } from "@material-ui/core/styles";
import { useSnackbar } from "notistack";
import { FormProvider, useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";
import logo from "assets/images/logo.png";
import { axiosInstance } from "services/api";
import { Field } from "shared/Field";
import { Footer } from "shared/Footer";
import { Loader } from "shared/Loader";
import { getBase64, getErrorMessage, matchPattern } from "utils";
import { checkEntityErrors } from "../components/Document/api";
import { UploadAttachmentForm } from "../components/Document/UploadAttachmentForm";

const useStyles = makeStyles((theme) => ({
	header: {
		backgroundColor: "white",
		minHeight: "100px",
		display: "flex",
		alignItems: "center",
		justifyContent: "flex-start",
	},
	logo: {
		[theme.breakpoints.down("sm")]: {
			textAlign: "center",
		},
	},
	content: {
		backgroundImage: "url('../assets/images/landing-page-background.png')",
		backgroundPosition: "top center",
		backgroundRepeat: "no-repeat",
		backgroundSize: "100%",
		[theme.breakpoints.down("sm")]: {
			backgroundSize: "auto 400px",
		},
	},
	paper: {
		height: "100%",
		display: "flex",
		textAlign: "center",
		alignItems: "center",
		transition: "all 0.3s",
		justifyContent: "center",
		padding: theme.spacing(2),
		cursor: "pointer",
		color: theme.palette.text.secondary,
		"&:hover": {
			color: "black",
			background: "transparent",
		},
	},
	title: {
		color: "white",
		textShadow: "0 0 7px #000",
	},
	errorMessage: {
		color: theme.palette.error.main,
		textAlign: "center",
	},
	successMessage: {
		textAlign: "center",
	},
	countdown: {
		color: "#317ED9",
	},
	formControl: {
		marginBottom: theme.spacing(2),
		width: "100%",
	},
	errorAlertIcon: {
		color: "#D16405",
		fontSize: 16,
	},
	privacyCheckboxLabel: {
		height: "20px",
		[theme.breakpoints.down("sm")]: {
			fontSize: "0.75rem",
		},
	},
	submitButton: {
		minWidth: "240px",
		maxWidth: "240px",
		borderRadius: 0,
		margin: "2rem auto",
		transform: "translateX(-50%)",
		position: "absolute",
		left: "50%",
		bottom: "0",
	},
	entitiesSubmitButton: {
		minWidth: "240px",
		borderRadius: 0,
		margin: "1rem auto",
		bottom: "0",
	},
	entitiesCancelButton: {
		minWidth: "240px",
		maxWidth: "240px",
		borderRadius: 0,
		margin: "1rem auto",
		position: "absolute",
		left: "30px",
		bottom: "0",
	},
	footerLinks: {
		fontFamily: "'Titillium Web', sans-serif",
		px: 15,
		[theme.breakpoints.down("sm")]: {
			flexDirection: "column",
			px: 2,
		},
	},
	select: {
		"& ul": {
			padding: 0,
		},
		"& li": {
			padding: "0.75rem",
			fontSize: 14,
		},
	},
	entitiesSubtitle: {
		color: "gray",
	},
}));

export function ValidateDichiarazione() {
	const classes = useStyles();
	const { enqueueSnackbar } = useSnackbar();

	const methods = useForm({
		mode: "onBlur",
	});

	const [loading, setLoading] = useState(true);
	const [submitted, setSubmitted] = useState(false);
	const [dichiaranteEntities, setDichiaranteEntities] = useState(null);
	const [delegatoEntities, setDelegatoEntities] = useState(null);
	const [typeLabels, setTypeLabels] = useState(null);
	const [typeValue, setTypeValue] = useState(null);
	const [file, setFile] = useState([]);
	const [showDelegato, setShowDelegato] = useState(null);
	const [error, setError] = useState(null);
	const [loaderMessage, setLoaderMessage] = useState(null);
	const [privacyModalOpen, setPrivacyModalOpen] = useState(false);
	const [polizzeResponse, setPolizzeResponse] = useState([]);
	const [codiceFiscalePivaResponse, setCodiceFiscalePivaResponse] = useState(
		[]
	);

	const cert = new URLSearchParams(useLocation().search).get("cert");

	useEffect(() => {
		axiosInstance
			.get("dichiarazione/services/certs/check", {
				params: {
					certificato: cert,
				},
				headers: {
					skipBearer: false,
				},
			})
			.then(({ data }) => {
				const isCertValid = data;
				setError(!isCertValid);
				setLoading(false);
				getCert();
			})
			.catch((error) => {
				enqueueSnackbar(getErrorMessage(error), {
					variant: "error",
					autoHideDuration: 12000,
				});

				setLoading(false);
			});
	}, []);

	function getCert() {
		setLoaderMessage("Stiamo recuperando i dati della tua richiesta...");
		setLoading(true);

		axiosInstance
			.get("dichiarazione/services/certs/fields", {
				params: {
					certificato: cert,
				},
				headers: {
					skipBearer: false,
				},
			})
			.then(({ data }) => {
				/*	if (data.entities_contraente.find((data) => data.name === "CF/PIVA CONTRAENTE")) {
          data.entities_contraente.forEach((data, index) => {
            if (data.name === "NOME CONTRAENTE") {
              data.mandatory = false;
              data.name = "RAGIONE SOCIALE";
              data.label = "Ragione sociale"
            }
            if (data.name === "COGNOME") {
              data.mandatory = false;
              data.value = "ragione_sociale";
            }
          });
        }*/
				setDichiaranteEntities(data.entities_contraente);
				setDelegatoEntities(data.entities_delegato);
				setPolizzeResponse(data.entities_np_resp);
				setCodiceFiscalePivaResponse(data.entities_np_cf);
				setTypeLabels(data.sub_label);
				setTypeValue(data.sub_label.value);
				setShowDelegato(data.required);
				setLoading(false);
				setLoaderMessage("");
			})
			.catch((error) => {
				enqueueSnackbar(getErrorMessage(error), {
					variant: "error",
					autoHideDuration: 12000,
				});
			});
	}

	function isAttachmentExtensionAccepted(extension) {
		return (
			extension === "pdf" ||
			extension === "docs" ||
			extension === "docx" ||
			extension === "txt"
		);
	}

	function onEntitiesSubmit(data) {
		if (showDelegato && file.length === 0)
			return enqueueSnackbar("File non aggiunto per il delegato", {
				variant: "error",
				autoHideDuration: 12000,
			});
		setLoaderMessage("Validazione richiesta in corso");
		setLoading(true);

		let newData = { ...data };

		delete newData["checkbox"];

		const toSend = {
			flag_delegato: showDelegato,
			sub_label: typeValue,
			certificato: cert,
			entities: newData || null,
			file_list: file,
		};

		axiosInstance
			.post("dichiarazione/services/certs/save", toSend,
				{
					headers: {
						skipBearer: false,
					}
				})
			.then(() => {
				setLoading(false);
				setSubmitted(true);
			})
			.catch((error) => {
				setLoading(false);

				enqueueSnackbar(getErrorMessage(error), {
					variant: "error",
					autoHideDuration: 12000,
				});
			});
	}

	function addNumeroPolizzaLiveCheck(numeroPolizzaEntity) {
		numeroPolizzaEntity.onChange = (newval, reason, callback) => {
			if (reason === "create-option" || reason === "select-option") {
				const insertedValue = newval[newval.length - 1];
				if (
					!/^[0-9]*$/.test(insertedValue) ||
					insertedValue.length === 11
				)
					return enqueueSnackbar("Formato polizza non corretto", {
						variant: "error",
						autoHideDuration: 12000,
					});
				if (
					codiceFiscalePivaResponse.length > 0 &&
					!codiceFiscalePivaResponse.find(
						(element) => element === insertedValue
					)
				) {
					return enqueueSnackbar(
						"Numero polizza non coincide con il codice fiscale/partita iva inserito",
						{
							variant: "error",
							autoHideDuration: 12000,
						}
					);
				}
				if (codiceFiscalePivaResponse.length > 0) {
					return callback(["toAdd"]);
				}
				setLoaderMessage("Ricerca numero polizza in corso");
				setLoading(true);
				checkEntityErrors(insertedValue)
					.then((response) => {
						setLoading(false);
						if (polizzeResponse.length > 0) {
							if (codiceFiscalePivaResponse.length === 0) {
								let responseCFPiva = response.data.map(
									(polizze) =>
										polizze.contraente.codiceFiscale?.replace(/\s+/g, '') ||
										polizze.contraente.partitaIVA?.replace(/\s+/g, '')
								);
								let cfPivaMatch = false;
								responseCFPiva.some((cfPiva) => {
									if (
										polizzeResponse.find(
											(polizze) =>
												polizze.contraente
													.codiceFiscale?.replace(/\s+/g, '') === cfPiva.replace(/\s+/g, '') ||
												polizze.contraente
													.partitaIVA?.replace(/\s+/g, '') === cfPiva.replace(/\s+/g, '')
										)
									) {
										cfPivaMatch = true;
										return true;
									}
								});
								if (!cfPivaMatch) {
									return enqueueSnackbar(
										"Numero polizza non riscontrabile con le informazioni già inserite",
										{
											variant: "error",
											autoHideDuration: 12000,
										}
									);
								}
								let newPolizzeResponse = [
									...polizzeResponse,
									response.data,
								];
								setPolizzeResponse(newPolizzeResponse);
							}
						} else {
							setPolizzeResponse(response.data);
						}
						callback(response.data);
						if (!response.data || response.data.length === 0)
							enqueueSnackbar("Polizza non trovata", {
								variant: "error",
								autoHideDuration: 12000,
							});
					})
					.catch((error) => {
						setLoading(false);
						enqueueSnackbar(getErrorMessage(error), {
							variant: "error",
							autoHideDuration: 12000,
						});
						return callback(undefined);
					});
			} else {
				if (newval.length === 0) {
					setPolizzeResponse([]);
				}
				return callback(["toDelete"]);
			}
		};
	}

	function addCodiceFiscaleLiveCheck(numeroPolizzaEntity) {
		let insertedValue = "";
		numeroPolizzaEntity.onChange = (e) => {
			insertedValue = e.target.value;
			if (
				insertedValue.length !== 0 &&
				insertedValue.length !== 16 &&
				insertedValue.length !== 11
			)
				return;
			if (!matchPattern("cf_piva").test(insertedValue)) {
				setCodiceFiscalePivaResponse([]);
				return enqueueSnackbar(
					"Formato codice fiscale/p. iva non corretto",
					{
						variant: "error",
						autoHideDuration: 12000,
					}
				);
			}
			const polizzeFiltered = polizzeResponse
				.filter(
					(polizza) =>
						polizza.contraente.codiceFiscale?.replace(/\s+/g, '') === insertedValue ||
						polizza.contraente.partitaIVA?.replace(/\s+/g, '') === insertedValue
				)
				.filter(
					(v, i, a) =>
						a.findIndex(
							(t) => t.numeroPolizza === v.numeroPolizza
						) === i
				);
			if (polizzeResponse.length !== 0 && polizzeFiltered.length === 0) {
				methods.setValue("CF/PIVA CONTRAENTE", "");
				return enqueueSnackbar(
					"Codice fiscale/p. iva non valido per i numeri di polizza inseriti",
					{
						variant: "error",
						autoHideDuration: 12000,
					}
				);
			}
			if (polizzeFiltered.length > 0) {
				changeContraenteLabels(insertedValue);
				return methods.setValue(
					"NUMERO POLIZZA",
					polizzeFiltered.map((value) => value.numeroPolizza)
				);
			}
			setLoading(true);
			setLoaderMessage("Ricerca codice fiscale/partita iva in corso");
			checkEntityErrors(insertedValue)
				.then((response) => {
					setLoading(false);
					if (!response.data || response.data.length === 0) {
						enqueueSnackbar(
							"Codice fiscale o partita iva non presenti",
							{
								variant: "error",
								autoHideDuration: 12000,
							}
						);
						methods.setValue("CF/PIVA CONTRAENTE", "");
						return;
					}
					setCodiceFiscalePivaResponse(response.data);
					changeContraenteLabels(insertedValue);
				})
				.catch((error) => {
					setLoading(false);
					enqueueSnackbar(getErrorMessage(error), {
						variant: "error",
						autoHideDuration: 12000,
					});
				});
		};
	}

	function changeContraenteLabels(insertedValue) {
		if (/^[0-9]{11}$/.test(insertedValue)) {
			let newEntities = [...dichiaranteEntities];
			newEntities.forEach((data, index, object) => {
				if (data.name === "NOME CONTRAENTE") {
					data.name = "RAGIONE SOCIALE";
					data.label = "Ragione sociale";
				}
				if (data.name === "COGNOME CONTRAENTE") {
					object.splice(index, 1);
				}
			});
			setDichiaranteEntities(newEntities);
		} else {
			let newEntities = [...dichiaranteEntities];
			newEntities.forEach((data, index, object) => {
				if (data.name === "RAGIONE SOCIALE") {
					data.name = "NOME CONTRAENTE";
					data.label = "Nome contraente";
					object.splice(index + 1, 0, {
						name: "COGNOME CONTRAENTE",
						mandatory: true,
						value: "",
						label: "Cognome contraente",
					});
				}
			});
			setDichiaranteEntities(newEntities);
		}
	}

	return (
		<Box id="landing_page">
			<Box id="header" className={classes.header}>
				<Container id="container" fixed={true} maxWidth="md">
					<Grid item xs={12} className={classes.logo}>
						<img
							src={logo}
							alt="Reale Mutua logo"
							width="170"
							height="42"
						/>
					</Grid>
				</Container>
			</Box>
			<Box id="content" className={classes.content}>
				<Container id="form_container" fixed>
					<Grid container direction="row" justify="center">
						<Grid item xs={12} sm={12} md={10} lg={7}>
							<Box my={4}>
								<Typography
									variant="h4"
									align="center"
									className={classes.title}
								>
									Richiesta integrazione dati
								</Typography>
							</Box>
							<Box my={4} pb={8} position="relative">
								<Loader
									isLoading={loading}
									spinnerSize={75}
									loadingLabel={loaderMessage}
								>
									<Paper
										square
										elevation={5}
										style={{
											minHeight: "50vh",
											padding: "2rem 2rem 2rem",
										}}
									>
										{error ? (
											<Typography
												variant="h5"
												paragraph={true}
												className={classes.errorMessage}
											>
												Link non valido
											</Typography>
										) : (
											<>
												{submitted ? (
													<Typography
														variant="h5"
														paragraph={true}
														className={
															classes.successMessage
														}
													>
														Abbiamo ricevuto la tua
														richiesta. Grazie
													</Typography>
												) : (
													<>
														{typeLabels && (
															<FormControl
																component="fieldset"
																style={{
																	marginBottom:
																		"0.9rem",
																	marginLeft:
																		"-10px",
																}}
															>
																<FormLabel component="legend">
																	Seleziona
																	tipo
																</FormLabel>
																<RadioGroup
																	aria-label="type"
																	row
																	name="controlled-radio-buttons-group"
																	value={
																		typeValue
																	}
																	onChange={(
																		e
																	) => {
																		setTypeValue(
																			e
																				.target
																				.value
																		);
																		let newDichiaranteEntities =
																			[
																				...dichiaranteEntities,
																			];
																		newDichiaranteEntities.forEach(
																			(
																				entity
																			) => {
																				if (
																					entity.name ===
																					"DATA RICHIESTA"
																				)
																					entity.label =
																						e
																							.target
																							.value ===
																						"ISEE"
																							? "Anno Richiesta (formato 'aaaa')"
																							: "Data richiesta (formato 'gg/mm/aaaa')";
																			}
																		);
																		setDichiaranteEntities(
																			newDichiaranteEntities
																		);
																	}}
																>
																	{typeLabels.possible_value.map(
																		(
																			value
																		) => (
																			<FormControlLabel
																				key={
																					value.value
																				}
																				value={
																					value.value
																				}
																				control={
																					<Radio size="small" />
																				}
																				label={
																					value.label
																				}
																			/>
																		)
																	)}
																</RadioGroup>
															</FormControl>
														)}
														{dichiaranteEntities ? (
															<FormProvider
																{...methods}
															>
																<form
																	onSubmit={methods.handleSubmit(
																		onEntitiesSubmit
																	)}
																>
																	<Grid
																		container
																	>
																		<Box
																			className={
																				classes.entitiesSubtitle
																			}
																		>
																			Dati
																			Contraente
																		</Box>
																		<Grid
																			style={{
																				border: "1px solid lightgray",
																				marginTop:
																					"0.9rem",
																				borderRadius:
																					"0.1rem",
																				marginBottom:
																					"0.9rem",
																			}}
																			container
																			spacing={
																				3
																			}
																		>
																			{dichiaranteEntities.map(
																				(
																					entity
																				) => {
																					if (
																						entity.name ===
																						"NUMERO POLIZZA"
																					)
																						addNumeroPolizzaLiveCheck(
																							entity
																						);
																					if (
																						entity.name ===
																						"CF/PIVA CONTRAENTE"
																					)
																						addCodiceFiscaleLiveCheck(
																							entity
																						);
																					return (
																						<Grid
																							key={
																								entity.name
																							}
																							item
																							xs={
																								12
																							}
																						>
																							<Field
																								entity={
																									entity
																								}
																								error={
																									methods
																										.errors[
																										entity
																											.name
																									]
																								}
																								entityRef={methods.register(
																									{
																										pattern:
																											matchPattern(
																												entity.pattern
																											),
																										required:
																											entity.mandatory,
																									}
																								)}
																							/>
																						</Grid>
																					);
																				}
																			)}
																		</Grid>
																		<Grid
																			container
																			style={{
																				alignItems:
																					"center",
																			}}
																		>
																			<Checkbox
																				onChange={(
																					event
																				) =>
																					setShowDelegato(
																						event
																							.target
																							.checked
																					)
																				}
																				checked={
																					showDelegato
																				}
																				style={{
																					paddingLeft:
																						"0",
																					marginLeft:
																						"-3px",
																				}}
																			/>
																			<Typography>
																				Sei
																				un
																				delegato?
																			</Typography>
																		</Grid>
																		{delegatoEntities &&
																			showDelegato && (
																				<>
																					<Box
																						className={
																							classes.entitiesSubtitle
																						}
																						style={{
																							marginTop:
																								"1.4rem",
																						}}
																					>
																						Dati
																						Delegato
																					</Box>
																					<Grid
																						style={{
																							border: "1px solid lightgray",
																							borderRadius:
																								"0.1rem",
																							marginTop:
																								"0.9rem",
																						}}
																						container
																						spacing={
																							3
																						}
																					>
																						{delegatoEntities.map(
																							(
																								entity
																							) => (
																								<Grid
																									key={
																										entity.name
																									}
																									item
																									xs={
																										12
																									}
																								>
																									<Field
																										entity={
																											entity
																										}
																										error={
																											methods
																												.errors[
																												entity
																													.name
																											]
																										}
																										entityRef={methods.register(
																											{
																												pattern:
																													matchPattern(
																														entity.pattern
																													),
																												required:
																													entity.mandatory,
																											}
																										)}
																									/>
																								</Grid>
																							)
																						)}
																						<Grid
																							item
																							xs={
																								12
																							}
																						>
																							<UploadAttachmentForm
																								stringFileAccepted={
																									"*Formati di allegato supportati dal documentale: PDF, DOC, DOCS, TXT"
																								}
																								style={{
																									width: "100%",
																								}}
																								files={
																									file
																								}
																								handleSetFile={(
																									attachment
																								) => {
																									const extension =
																										attachment[0].name
																											.toLowerCase()
																											.split(
																												"."
																											)
																											.pop();
																									if (
																										!isAttachmentExtensionAccepted(
																											extension
																										)
																									)
																										return enqueueSnackbar(
																											"Formato del file non accettato",
																											{
																												variant:
																													"error",
																											}
																										);
																									getBase64(
																										attachment[0],
																										(
																											result
																										) => {
																											const extension =
																												attachment[0].name
																													.toLowerCase()
																													.split(
																														"."
																													)
																													.pop();
																											const newDocumentAttachment =
																												{
																													filename:
																														attachment[0]
																															.name,
																													flag_upl: true,
																													content:
																														result,
																												};
																											let newDocumentAttachmentFiles =
																												file.filter(
																													(
																														doc
																													) =>
																														doc.filename !==
																														attachment[0]
																															.name
																												);
																											newDocumentAttachmentFiles.push(
																												newDocumentAttachment
																											);
																											setFile(
																												newDocumentAttachmentFiles
																											);
																										}
																									);
																								}}
																								buttonMarginRight={{
																									marginRight:
																										"20px",
																								}}
																								buttonStyle={{
																									minWidth:
																										"150px",
																								}}
																								buttonSize="small"
																							/>
																						</Grid>
																					</Grid>
																				</>
																			)}
																		<Grid
																			item
																			xs={
																				12
																			}
																			style={{
																				marginTop:
																					"1.6rem",
																				marginBottom:
																					"0.7rem",
																			}}
																		>
																			<FormControl
																				required
																				error={
																					!!methods
																						.errors[
																						"checkbox"
																					]
																				}
																			>
																				<FormControlLabel
																					className={
																						classes.privacyCheckboxLabel
																					}
																					name="checkbox"
																					inputRef={methods.register(
																						{
																							required: true,
																						}
																					)}
																					control={
																						<Checkbox color="primary" />
																					}
																					label={
																						<span>
																							Dichiaro
																							di
																							aver
																							letto{" "}
																							<Link
																								href="#"
																								onClick={(
																									e
																								) => {
																									e.preventDefault();
																									setPrivacyModalOpen(
																										true
																									);
																								}}
																							>
																								l&apos;informativa
																								sulla
																								privacy
																							</Link>
																						</span>
																					}
																				/>
																				{!!methods
																					.errors[
																					"checkbox"
																				] && (
																					<FormHelperText
																						style={{
																							marginLeft:
																								"32px",
																						}}
																					>
																						Richiesto
																					</FormHelperText>
																				)}
																			</FormControl>
																		</Grid>
																	</Grid>
																	<Button
																		color="secondary"
																		variant="contained"
																		fullWidth
																		type="submit"
																		size="large"
																		className={
																			classes.entitiesSubmitButton
																		}
																	>
																		Invia
																	</Button>
																</form>
															</FormProvider>
														) : (
															<>
																{!cert && (
																	<Typography
																		variant="h5"
																		paragraph={
																			true
																		}
																		className={
																			classes.errorMessage
																		}
																	>
																		Nessun
																		certificato
																		inserito
																	</Typography>
																)}
															</>
														)}
													</>
												)}
											</>
										)}
									</Paper>
								</Loader>
							</Box>
						</Grid>
					</Grid>
				</Container>
			</Box>

			<Dialog
				open={privacyModalOpen}
				onClose={() => setPrivacyModalOpen(false)}
			>
				<DialogTitle>Informativa sulla privacy</DialogTitle>
				<DialogContent>
					<DialogContentText>
						I dati personali richiesti saranno trattati per le
						finalità e secondo le modalità indicate nell’informativa
						privacy per contraenti e assicurati redatta ai sensi
						degli artt. 13 e 14 del Regolamento (UE) 2016/679
						(Regolamento generale sulla protezione dei dati) che le
						è stata consegnata in virtù del rapporto contrattuale in
						essere con la società; potrà consultare l’informativa
						nella sezione “cookie e privacy” sul sito della
						Compagnia.
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button
						onClick={() => setPrivacyModalOpen(false)}
						color="primary"
						autoFocus
					>
						Chiudi
					</Button>
				</DialogActions>
			</Dialog>

			<Footer />
		</Box>
	);
}
