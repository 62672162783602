import { createMuiTheme, responsiveFontSizes } from "@material-ui/core/styles";

let theme = createMuiTheme({
	palette: {
		error: { main: "#ff2200" },
		primary: {
			// light: will be calculated from palette.primary.main,
			main: "#317ED9",
			// dark: will be calculated from palette.primary.main,
			// contrastText: will be calculated to contrast with palette.primary.main
		},
		secondary: {
			// light: '#0066ff',
			main: "#F0B400",
			dark: "#E5AC00",
			contrastText: "#ffffff",
		},
		// Used by `getContrastText()` to maximize the contrast between
		// the background and the text.
		contrastThreshold: 3,
		// Used by the functions below to shift a color's luminance by approximately
		// two indexes within its tonal palette.
		// E.g., shift from Red 500 to Red 300 or Red 700.
		tonalOffset: 0.2,
	},
	typography: {
		h1: {
			fontFamily: "'Titillium Web', 'sans-serif'",
			fontWeight: "bold",
		},
		h2: {
			fontFamily: "'Titillium Web', 'sans-serif'",
			fontWeight: "bold",
		},
		h3: {
			fontFamily: "'Titillium Web', 'sans-serif'",
			fontWeight: "bold",
		},
		h4: {
			fontFamily: "'Titillium Web', 'sans-serif'",
			fontWeight: "bold",
		},
		h5: {
			fontFamily: "'Titillium Web', 'sans-serif'",
			fontWeight: "bold",
		},
		h6: {
			fontFamily: "'Titillium Web', 'sans-serif'",
			fontWeight: "bold",
		},
	},
	props: {
		MuiTypography: {
			variantMapping: {
				h1: "h2",
				h2: "h2",
				h3: "h3",
				h4: "h4",
				h5: "h5",
				h6: "h6",
				subtitle1: "h2",
				subtitle2: "h2",
				body1: "span",
				body2: "span",
			},
		},
	},
	overrides: {
		MuiCssBaseline: {
			"@global": {
				html: {
					WebkitFontSmoothing: "auto",
				},
				body: {
					backgroundColor: "#fafafa",
					background: "#F0B400",
					fontFamily: "'Roboto', 'Helvetica Neue', 'sans-serif'",
				},
			},
		},
		// Style sheet name ⚛️
		MuiButton: {
			// Name of the rule
			text: {
				// Some CSS
				color: "black",
			},
		},
		MuiAccordion: {
			root: {
				"&$disabled": {
					opacity: 0.25,
				},
			},
		},
		MuiIconButton: {
			root: {
				padding: "4px",
			},
		},
		MuiIcon: {
			fontSizeSmall: {
				// fontSize: "1rem",
			},
		},
		MuiMenu: {
			list: {
				padding: 0,
			},
		},
		MuiMenuItem: {
			root: {
				paddingTop: 0,
				paddingBottom: 0,
				whiteSpace: "wrap",
			},
			gutters: {
				paddingLeft: 0,
				paddingRight: 3,
			},
		},
	},
});

theme = responsiveFontSizes(theme);

export { theme };
