import React from "react";
import { Box } from "@material-ui/core";
import { useRole } from "utils/hooks";
import { Unauthorized } from "views/Unauthorized/Unauthorized";

export default function Auth({ children }) {
	const roles = useRole();
	// const roles = {
	// 	isConfig: true,
	// 	isUffVita: false,
	// 	isITUser: true,
	// 	isOper2: false,
	// };
	// console.log(roles);

	const isAuthorized = roles.isUffVita || roles.isITUser;
	const isReadOnly = roles.isITUser && !roles.isUffVita;

	return (
		<Box>
			{isAuthorized !== undefined ? (
				isAuthorized ? (
					children(isReadOnly)
				) : (
					<Unauthorized />
				)
			) : null}
		</Box>
	);
}
