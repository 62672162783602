/* eslint-disable react/display-name */
import React, { useState } from "react";
import {
	AppBar,
	Box,
	Grid,
	IconButton,
	makeStyles,
	Paper,
	Tab,
	Tabs,
	Tooltip,
	Typography,
} from "@material-ui/core";
import { ArrowBack } from "@material-ui/icons";
import { useHistory } from "react-router-dom";
import { useRole } from "utils/hooks";
import { KeywordsSettings } from "./KeywordsSettings";
import { ReceiversSettings } from "./ReceiversSettings";
import { SendersSettings } from "./SendersSettings";

const danger = "#D16405";

const useStyles = makeStyles((theme) => ({
	paper: {
		height: "100%",
		display: "flex",
		textAlign: "center",
		alignItems: "center",
		transition: "all 0.3s",
		justifyContent: "center",
		padding: theme.spacing(2),
		cursor: "pointer",
		color: theme.palette.text.secondary,
		"&:hover": {
			color: "black",
			background: "transparent",
		},
	},
	header: {
		paddingBottom: "2rem",
		paddingLeft: "1rem",
		// borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
		// marginBottom: theme.spacing(3),
		justifyContent: "flex-start",
		alignItems: "center",
		display: "flex",
		flexWrap: "wrap",
		flexDirection: "row",
	},
	toolbar: {
		paddingBottom: "1rem",
	},
	actions: {
		display: "flex",
		justifyContent: "flex-end",
		margin: "2rem 0 1rem",
	},
	action: {
		marginLeft: "1rem",
		boxShadow: "0 0 0 #000",
		backgroundColor: theme.palette.secondary.main,
	},
	dangerAction: {
		backgroundColor: danger,
	},
	actionCell: {
		maxWidth: "1rem",
	},
}));

function TabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`full-width-tabpanel-${index}`}
			aria-labelledby={`full-width-tab-${index}`}
			{...other}
		>
			{value === index && (
				<Box py={3}>
					<Typography>{children}</Typography>
				</Box>
			)}
		</div>
	);
}

export function Settings() {
	const classes = useStyles();
	const roles = useRole();

	// const roles = {
	// 	isConfig: true,
	// 	isUffVita: false,
	// 	isITUser: true,
	// 	isOper2: false,
	// };

	const [value, setValue] = useState(0);

	const settingsTabs = [
		{
			name: "senders",
			label: "Origine del dato - SDD",
			action: () => <SendersSettings roles={roles} />,
		},
		{
			name: "receivers",
			label: "Risposte - SDD",
			action: () => <ReceiversSettings roles={roles} />,
		},
		{
			name: "keywords",
			label: "Regole classificazione - SDD",
			action: () => <KeywordsSettings roles={roles} />,
		},
	];

	const handleChange = (event, newValue) => {
		setValue(newValue);
	};

	return (
		<Grid container justify="center" spacing={2}>
			<Grid item xs={12}>
				<Grid item className={classes.toolbar}>
					<AppBar position="static">
						<Tabs value={value} onChange={handleChange}>
							{settingsTabs.map((setting) => (
								<Tab
									key={setting.label}
									label={setting.label}
								/>
							))}
						</Tabs>
					</AppBar>

					{settingsTabs.map((setting, index) => (
						<TabPanel
							key={setting.label}
							value={value}
							index={index}
						>
							{setting.action()}
						</TabPanel>
					))}
				</Grid>
			</Grid>
		</Grid>
	);
}
