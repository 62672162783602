/* eslint-disable react/prop-types */
/* eslint-disable react/display-name */
import React, { useEffect, useState } from "react";
import { Box, makeStyles, MenuItem, Select } from "@material-ui/core";
import MaterialTable from "material-table";
import moment from "moment";
import "moment/locale/it";
import { tableIntl } from "config/constants";
import { Loader } from "shared/Loader";

moment.locale("it");

const useStyles = makeStyles(() => ({
	table: {
		width: "100%",
		marginTop: "12px",
	},
}));

export function SettingsTable({
	emailsSettings,
	onEmailsSettingsSave,
	onEmailDelete,
	isLoading,
	roles,
}) {
	const classes = useStyles();

	const [activeRow, setActiveRow] = useState(null);
	const [columns, setColumns] = useState([]);

	const headerStyle = {
		padding: "2px 16px",
		position: "sticky",
		fontSize: "0.8125rem",
		top: 0,
	};

	const actionsCellStyle = {
		textAlign: "right",
	};

	const cellStyle = {
		fontSize: "0.8125rem", // 100:16=x:13 => 1300/16
		width: "140px",
		minWidth: "140px",
		maxWidth: "140px",
		overflow: "hidden",
		whiteSpace: "nowrap",
		textOverflow: "ellipsis",
		padding: "2px 16px",
	};

	function handleSetColumns() {
		setColumns([
			{
				title: "Email",
				field: "email",
				editable: "onAdd",
				cellStyle,
			},
			{
				title: "Tipologia",
				field: "type",
				cellStyle,
				editComponent: (props) => (
					<Select
						value={props.value}
						onChange={(e) => {
							{
								props.onChange(e.target.value);
								handleTypeChange(e, props.rowData);
							}
						}}
					>
						<MenuItem value="agente">Agente</MenuItem>
						<MenuItem value="cliente">Cliente</MenuItem>
					</Select>
				),
			},
			{
				title: "Compagnia",
				field: "group",
				cellStyle,
				editComponent: (props) =>
					(activeRow?.type === "agente" ||
						props.rowData?.type === "agente") && (
						<Select
							value={props.value}
							onChange={(e) => {
								props.onChange(e.target.value);
								handleGroupChange(e, props.rowData);
							}}
						>
							<MenuItem value="RMA">Reale Mutua</MenuItem>
							<MenuItem value="ITA">Italiana</MenuItem>
						</Select>
					),
			},
		]);
	}

	const handleTypeChange = (e, rowData) => {
		rowData.type = e.target.value;
		setActiveRow(rowData);
	};

	const handleGroupChange = (e, rowData) => {
		rowData.group = e.target.value;
		setActiveRow(rowData);
	};

	const [data, setData] = useState(null);

	useEffect(() => {
		if (!activeRow) {
			handleSetColumns();
			const emailData = emailsSettings.map((email) => {
				// const gr = email.type === "agente" ? email.group : "";
				return {
					email: email.email,
					type: email.type,
					group:
						email.type === "agente"
							? email.group
							: "Definito da polizza",
				};
			});
			setData(emailData);
		}
	}, [emailsSettings]);

	useEffect(() => {
		if (activeRow) {
			const emailsExisting = emailsSettings.map((email) => email.email);
			if (emailsExisting.includes(activeRow.email)) {
				const emailData = emailsSettings.map((email) => {
					let tp = email.type;
					let gr =
						email.group === null
							? "Definito da polizza"
							: email.group;
					if (email.email === activeRow.email) {
						tp = activeRow.type;
						gr =
							activeRow.type === "agente"
								? activeRow.group !== "Definito da polizza"
									? activeRow.group
									: "RMA"
								: // ? activeRow.group !== "Definito da polizza"
								  // 	? activeRow.group
								  // 	: email.group !== null ||
								  // 	  email.group !== "" ||
								  // 	  email.group !== "Definito da polizza"
								  // 	? email.group
								  // 	: "RMA"
								  "Definito da polizza";
					}
					return {
						email: email.email,
						type: tp,
						group: gr,
					};
				});
				handleSetColumns();

				setData(emailData);
				onEmailsSettingsSave(emailData);
			}
		}
	}, [activeRow]);

	return (
		<Box className={classes.table}>
			<Loader isLoading={isLoading} spinnerSize={40}>
				{data && (
					<MaterialTable
						title="Indirizzi email di inoltro"
						columns={columns}
						data={data}
						options={{
							actionsColumnIndex: -1,
							pageSize: 10,
							headerStyle,
							actionsCellStyle,
						}}
						localization={{
							...tableIntl,
							header: {
								actions: "Modifica/Elimina",
							},
						}}
						editable={
							roles.isConfig
								? {
										onRowAddCancelled: () => {
											setActiveRow(null);
										},

										onRowUpdateCancelled: () => {
											setActiveRow(null);
										},
										onRowAdd: (newData) =>
											new Promise((resolve) => {
												setTimeout(() => {
													setActiveRow(null);
													onEmailsSettingsSave([
														...data,
														newData,
													]);
													setData([...data, newData]);
													resolve();
												}, 1000);
											}),
										onRowUpdate: (newData, oldData) => {
											return new Promise((resolve) => {
												setTimeout(() => {
													const dataUpdate = [
														...data,
													];
													const index =
														oldData.tableData.id;
													dataUpdate[index] = newData;

													setData([...dataUpdate]);

													resolve();
												}, 1000);
											});
										},
										onRowDelete: (oldData) =>
											new Promise((resolve) => {
												setTimeout(() => {
													const dataDelete = [
														...data,
													];
													const index =
														oldData.tableData.id;
													dataDelete.splice(index, 1);
													setData([...dataDelete]);
													onEmailDelete(
														oldData.email
													);
													resolve();
												}, 1000);
											}),
								  }
								: {}
						}
					/>
				)}
			</Loader>
		</Box>
	);
}
